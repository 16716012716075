const relativeTime = require("dayjs/plugin/relativeTime");
const localizedFormat = require("dayjs/plugin/localizedFormat");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone"); 
export const DATE_DISPLAY_FORMAT = "MMMM D, YYYY h:mm A";
export const DATE_DISPLAY_FORMAT_DETAILED = "MMMM D, YYYY h:mm:ss A";
export const dayjs = require("dayjs");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
