import { API_ROOT } from "config.js";

const USER_WIDGETS_LIST_ENDPOINT = `${API_ROOT}/api/dashboard/widgets/`;
const USER_VIEWS_LIST_ENDPOINT = `${API_ROOT}/api/dashboard/views/`;
const DEVICE_LIST_ENDPOINT = `${API_ROOT}/api/devices/`;
const DEVICE_DETAILS_ENDPOINT = `${API_ROOT}/api/device/`;

export async function getUserWidgets(axios) {
  const response = await axios.get(`${USER_WIDGETS_LIST_ENDPOINT}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getUserViews(axios, view_types) {
  const response = await axios.get(
    `${USER_VIEWS_LIST_ENDPOINT}?view_types=${view_types}`
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getUserDevices(axios, deviceId) {
  let url = `${DEVICE_LIST_ENDPOINT}`;
  if (deviceId !== undefined) {
    url = `${DEVICE_DETAILS_ENDPOINT}staticdata/${deviceId}`;
  }
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function createUserDevice(axios, deviceData) {
  let payload = deviceData;
  let url = `${DEVICE_LIST_ENDPOINT}`;
  if (deviceData.avatarFile) {
    payload = new FormData();
    payload.append("file", deviceData.avatarFile);
    delete deviceData["avatarFile"];

    const json = JSON.stringify(deviceData);
    const blob = new Blob([json], {
      type: "application/json"
    });
    payload.append("document", blob);
  }
  const response = await axios.post(url, payload);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function removeUserDevice(axios, deviceId) {
  let url = `${DEVICE_DETAILS_ENDPOINT}staticdata/${deviceId}`;
  const response = await axios.delete(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateUserDevice(axios, deviceId, deviceData) {
  let payload = deviceData;
  let url = `${DEVICE_DETAILS_ENDPOINT}staticdata/${deviceId}`;
  if (deviceData.avatarFile) {
    payload = new FormData();
    payload.append("file", deviceData.avatarFile);
    delete deviceData["avatarFile"];

    const json = JSON.stringify(deviceData);
    const blob = new Blob([json], {
      type: "application/json"
    });
    payload.append("document", blob);
  }
  const response = await axios.post(url, payload);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getDeviceReports(axios, deviceId, reportType) {
  let url = `${DEVICE_DETAILS_ENDPOINT}reportdata/${deviceId}/${reportType}`;
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getDeviceLogs(axios, deviceId, date, format) {
  let url = `${DEVICE_DETAILS_ENDPOINT}logs/${deviceId}?date=${date}&format=${format}`;
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function downloadDeviceData(axios, deviceId, deviceData) {
  let payload = deviceData;
  let url = `${DEVICE_DETAILS_ENDPOINT}dynamicdata/${deviceId}`;
  const response = await axios.post(url, payload);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function markDeviceFavorite(axios, deviceId) {
  const response = await axios.get(
    `${DEVICE_LIST_ENDPOINT}markfavorite/${deviceId}`
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function unmarkDeviceFavorite(axios, deviceId) {
  const response = await axios.delete(
    `${DEVICE_LIST_ENDPOINT}removefavorite/${deviceId}`
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
