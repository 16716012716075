import { API_ROOT } from "config.js";

const USER_DETAILS_ENDPOINT = `${API_ROOT}/api/user/details/`;
const NOTIFICATIONS_ENDPOINT = `${API_ROOT}/api/notifications/`;

export async function getData(axios, user_id) {
  let url = `${USER_DETAILS_ENDPOINT}`;
  if (user_id) {
    url = `${USER_DETAILS_ENDPOINT}${user_id}`;
  }
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateData(axios, user_id, userData) {
  let payload = userData;
  if (userData.avatarFile) {
    payload = new FormData();
    payload.append("file", userData.avatarFile);
    delete userData["avatarFile"];

    const json = JSON.stringify(userData);
    const blob = new Blob([json], {
      type: "application/json"
    });
    payload.append("document", blob);
  }
  const response = await axios.post(
    `${USER_DETAILS_ENDPOINT}${user_id}/`,
    payload
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function create(axios, user_id, userData) {
  const response = await axios.post(
    `${USER_DETAILS_ENDPOINT}${user_id}/`,
    userData
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getNotifications(axios) {
  const response = await axios.get(`${NOTIFICATIONS_ENDPOINT}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function readNotification(axios, notification_id) {
  let payload = {
    unread: false
  };
  const response = await axios.post(
    `${NOTIFICATIONS_ENDPOINT}${notification_id}/`,
    payload
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
