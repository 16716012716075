export const DEVICE_TYPES = {
  DELTA_RPI_INVERTER: "Delta-RPI Inverter",
  WEATHER_STATION: "WEATHER STATION",
  SOLAR_HYBRID_INVERTER: "SOLAR HYBRID INVERTER",
  IOT_GATEWAY: "IOT_GATEWAY",
  "IOT-GW-V2-MODBUS-WIFI": "IOT-GW-V2-MODBUS-WIFI",
  "IOT-GW-SHAKTI-SOLAR-PUMP": "IOT-GW-SHAKTI-SOLAR-PUMP",
  "IOT-GW-SOLAR-CC": "IOT-GW-SOLAR-CC",
  OTHER: "OTHER"
};

export const AVAILABLE_DEVICE_TYPES = [
  { text: "Charge Controller", value: "CHARGE_CONTROLLER" },
  { text: "Weather Station", value: "WEATHER_STATION" },
  { text: "Iot Gateway", value: "IOT_GATEWAY" },
  { text: "Other", value: "OTHER" }
];
