import React, { useRef, useEffect } from "react";

const Battery = props => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const {
      voltage,
      temperature,
      capacityKwh,
      capacityPercentage,
      capColor,
      backgroundColor,
      outlineColor,
      textColor,
      height,
      width,
      status
    } = props;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    //Our first draw
    // context.fillStyle = "#000000";
    // context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    const battHeight = (context.canvas.height * (height || 100)) / 100;
    const battWidth = (context.canvas.width * (width || 100)) / 100;

    const data = [capacityKwh, capacityPercentage, 30];

    let start_x = 0,
      start_y = 0;
    let cap_height = battHeight / 16 || 5;
    let cap_width = battWidth / 3 || 3;
    let line_width = 2;
    let margin = 1;
    let number_of_blocks = 5;

    context.globalAlpha = 1;

    //Drawing the battery cap
    context.fillStyle = capColor || "#34ee00";
    context.fillRect(
      start_x + battWidth / 2 - cap_width / 2,
      start_y,
      cap_width,
      cap_height
    );

    //Clear the battery area first
    context.fillStyle = backgroundColor || "#ffffff";
    context.fillRect(
      start_x,
      start_y + cap_height,
      battWidth,
      battHeight - cap_height
    );
    //Drawing body outline
    context.strokeStyle = outlineColor || "#000f00";
    context.lineWidth = line_width;
    context.strokeRect(
      start_x,
      start_y + cap_height,
      battWidth,
      battHeight - cap_height
    );

    //Filling body
    var block_width = battWidth - 2 * (line_width + margin);
    var block_height =
      Math.ceil(
        (battHeight - cap_height - 2 * (line_width + margin)) / number_of_blocks
      ) -
      2 * margin;
    var block_start_y = start_y + battHeight - line_width - margin;

    var last_block = Math.ceil((number_of_blocks * data[1]) / 100);
    var green_val = Math.floor((data[1] * 255) / 100); //<49?255:0;

    var red_val = 255 - green_val;
    var yellow_val = red_val * 0.5;
    var linearGradient1 = context.createLinearGradient(
      start_x + line_width + margin,
      start_y + cap_height + line_width + margin,
      start_x + line_width + margin + block_width,
      start_y + cap_height + line_width + margin
    );
    linearGradient1.addColorStop(
      0,
      "rgb(" + red_val + ", " + green_val + ", " + yellow_val + ")"
    );
    linearGradient1.addColorStop(0.5, "rgb(0, 0, 0)");
    linearGradient1.addColorStop(
      1,
      "rgb(" + red_val + "," + green_val + ", " + yellow_val + ")"
    );

    for (var i = 1; i <= last_block; i++) {
      var y_pos = start_y + block_start_y - i * (block_height + margin);

      context.fillStyle = linearGradient1;
      context.fillRect(
        start_x + line_width + margin,
        y_pos,
        block_width,
        block_height
      );
    }

    context.font = "16px Verdana";
    context.fillStyle = textColor || "#ff0000";
    if (voltage !== undefined) {
      context.fillText(
        voltage + "V",
        start_x + battWidth / 4,
        start_y + battHeight / 2 - 15,
        battWidth
      );
    }

    if (capacityPercentage !== undefined) {
      context.fillText(
        capacityPercentage + "%",
        start_x + battWidth / 4,
        start_y + battHeight / 2 + 10,
        battWidth
      );
    }

    if (temperature !== undefined) {
      context.fillText(
        temperature + "℃",
        start_x + battWidth / 4,
        start_y + battHeight / 2 + 37,
        battWidth
      );
    }

    if (status !== undefined) {
      context.fillText(
        status,
        start_x + battWidth / 4,
        start_y + battHeight / 2 + 37,
        battWidth
      );
    }
  }, []);

  return <canvas ref={canvasRef} {...props} />;
};

export default Battery;
