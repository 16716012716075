import logo from "assets/img/data-explorer.png";

export const staticData = {
  MadeWithLove: {
    orgTitle: "OKOS Engineering",
    orgLink: "https://okosengineering.com/",
    tagline: "Built by the "
  },
  company: {
    name: "OKOS Engineering",
    logoText: "IoT Dashboard",
    logoURL: logo,
    url: "https://mona.okosengineering.com/",
    tagline: ""
  }
};

// export const API_ROOT = "http://localhost:8113";
// export const MEDIA_ROOT = "http://localhost:8113";
// export const WEB_SOCKET_API_PATH = "ws://localhost:8001/ws/chat";
// export const SENTRY_DSN = "";

export const SENTRY_DSN =
  "https://4c27a1988da8480caddceb72326fcd5a@sentry.io/146090";
export const API_ROOT = "https://iot.okosengineering.com";
export const MEDIA_ROOT = "https://iot.okosengineering.com";
export const WEB_SOCKET_API_PATH = ""; //"ws://iot.okosengineering.com/ws/chat";