/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core components
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { Input } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import { getData, updateData } from "services/userDataProvider.js";
import { prettyJson } from "../../utils/converters.jsx";
import { MEDIA_ROOT } from "config.js";

const AVAILABLE_STATUS_TYPES = [
  { text: "Meter", value: "meter" },
  { text: "User", value: "user" },
  { text: "Alarm", value: "alarm" },
  { text: "Device", value: "device" },
  { text: "Report", value: "report" }
];

const AVAILABLE_STATUS_TRIGGER_TYPES = [
  { text: "Data", value: "data" },
  { text: "Schedule", value: "schedule" },
  { text: "Data and Schedule", value: "data/schedule" }
];

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  deviceTypeContainer: {
    maxHeight: "600px",
    overflow: "scroll"
  },
  deviceTypeSelectorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  rawTelemetryDataContainer: {
    display: "flex",
    flexDirection: "column",
    overflowX: "scroll"
  },
  jsonContainer: {
    display: "flex",
    flexDirection: "column"
  },
  jsonKeyValuePair: {
    display: "flex",
    flexDirection: "row"
  },
  jsonKey: {
    fontSize: "larger",
    color: "#0057ff",
    display: "flex",
    flexDirection: "row"
  },
  jsonKeyValue: {
    display: "flex",
    flexDirection: "row",
    color: "#ff00a0",
    paddingLeft: "5px"
  },
  alarmActions: {
    display: "flex",
    justifyContent: "flex-end"
  },
  alarmModalBox: {
    padding: "15px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "80%",
    overflow: "scroll",
    opacity: "100%",
    zIndex: 2000,
    backgroundColor: "white",
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    "-webkit-box-shadow": "0 3px 7px rgba(0, 0, 0, 0.3)",
    "-moz-box-shadow": "0 3px 7px rgba(0, 0, 0, 0.3)",
    boxShadow: "0 3px 7px rgba(0, 0, 0, 0.3)",
    "-webkit-background-clip": "padding-box",
    "-moz-background-clip": "padding-box",
    backgroundClip: "padding-box"
  },
  alarmSelectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px"
  }
};

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedData: {}
    };
  }

  updateProfile = e => {
    updateData(this.props.axios, this.state.user_id, this.state.updatedData);
  };

  onFileLoad = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      let updatedData = this.state.updatedData;
      updatedData["avatarFile"] = file;
      this.setState({ updatedData: updatedData });
    }
  };

  onChange = name => e => {
    let updatedData = this.state.updatedData;
    updatedData[name] = e.target.value;
    this.setState({ [name]: e.target.value, updatedData: updatedData });
  };

  onDataChange = (dataType, idx, fieldName) => e => {
    let dataList = this.state[dataType];
    if (dataList && dataList[idx]) {
      let value = e.target.value;
      if (fieldName === "data_schema" || fieldName === "translation_schema") {
        if (this.isValidJson(value)) {
          value = JSON.parse(value);
          dataList[idx][fieldName] = value;
        }
      } else {
        dataList[idx][fieldName] = value;
      }
      this.setState({ [`updated${dataType}`]: dataList });
    }
  };

  onSelectionChange = (dataType, idx, fieldName) => value => {
    let dataList = this.state[dataType];
    if (dataList && dataList[idx]) {
      dataList[idx][fieldName] = value;
      this.setState({ [`updated${dataType}`]: dataList });
    }
  };

  removeDeviceType = idx => {
    let available_device_types = this.state.available_device_types || [];
    if (window.confirm("Delete device type?")) {
      available_device_types.splice(idx, 1);
      this.setState(
        { updatedavailable_device_types: available_device_types },
        () => this.updateDeviceType()
      );
    }
  };

  removeStatusType = idx => {
    let available_status_types = this.state.available_status_types || [];
    if (window.confirm("Delete status type?")) {
      available_status_types.splice(idx, 1);
      this.setState(
        { updatedavailable_status_types: available_status_types },
        () => this.updateStatusType()
      );
    }
  };

  addNewDeviceType() {
    let available_device_types = this.state.available_device_types || [];
    let l = available_device_types.length;
    available_device_types.push({});
    this.setState({
      visibleDeviceType: l,
      available_device_types: available_device_types,
      editDeviceTypeDetails: true
    });
  }

  addNewStatusType() {
    let available_status_types = this.state.available_status_types || [];
    let l = available_status_types.length;
    available_status_types.push({});
    this.setState({
      visibleStatusType: l,
      available_status_types: available_status_types,
      editStatusTypeDetails: true
    });
  }

  isValidJson(text) {
    if (typeof text !== "string") {
      return false;
    }
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  }

  updateStatusType() {
    const updatedStatusData = this.state.updatedavailable_status_types;
    let updatedData = this.state.updatedData || {};
    updatedData.available_status_types = updatedStatusData;
    updatedData.available_device_types = this.state.available_device_types;
    if (updatedStatusData) {
      updateData(this.props.axios, this.state.user_id, updatedData)
        .then(data => {
          this.setState({
            ...data,
            visibleStatusType: null,
            editStatusTypeDetails: false
          });
        })
        .catch(err => {
          console.log("Failed to update!", err);
          alert("Failed to update!");
        });
    }
  }

  updateDeviceType() {
    const updatedStatusData = this.state.updatedavailable_device_types;
    let updatedData = this.state.updatedData || {};
    updatedData.available_status_types = this.state.available_status_types;
    updatedData.available_device_types = updatedStatusData;
    if (updatedStatusData) {
      updateData(this.props.axios, this.state.user_id, updatedData)
        .then(data => {
          this.setState({
            ...data,
            visibleDeviceType: null,
            editStatusTypeDetails: false
          });
        })
        .catch(err => {
          console.log("Failed to update!", err);
          alert("Failed to update!");
        });
    }
  }

  componentDidMount() {
    getData(this.props.axios, this.props.user.user_id).then(data =>
      this.setState({ ...data })
    );
  }

  renderDeviceType(deviceType, idx) {
    const { classes, user } = this.props;
    return (
      <div key={`device-type-${idx}`} className={classes.alarmsContainer}>
        <div className={classes.alarmActions}>
          <Modal
            hideBackdrop
            open={this.state.visibleDeviceType === idx}
            onClose={() => this.setState({ visibleDeviceType: null })}
            aria-labelledby="Add"
            aria-describedby="Add/Update device type"
          >
            <Box className={classes.alarmModalBox} sx={{ width: 400 }}>
              <div className={classes.alarmActions}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!(user.isAdmin === true)}
                      checked={this.state.editDeviceTypeDetails === true}
                      onChange={() => {
                        this.setState({
                          editDeviceTypeDetails: !this.state
                            .editDeviceTypeDetails
                        });
                      }}
                    />
                  }
                  label="Edit"
                />
                <IconButton onClick={() => this.removeDeviceType(idx)}>
                  <DeleteIcon />
                </IconButton>
              </div>
              <div className={classes.deviceTypeContainer}>
                <div>
                  <CustomInput
                    labelText="Name"
                    id={`device_type-name-${idx}`}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: deviceType.name,
                      disabled: this.state.editDeviceTypeDetails !== true,
                      onChange: this.onDataChange(
                        "available_device_types",
                        idx,
                        "name"
                      )
                    }}
                  />
                </div>
                <div>
                  <CustomInput
                    labelText="Details"
                    id={`device_type-details-${idx}`}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: deviceType.details,
                      disabled: this.state.editDeviceTypeDetails !== true,
                      onChange: this.onDataChange(
                        "available_device_types",
                        idx,
                        "details"
                      )
                    }}
                  />
                </div>
                <div>
                  {this.state.editDeviceTypeDetails !== true ? (
                    <div className={classes.rawTelemetryDataContainer}>
                      {prettyJson(deviceType.data_schema, classes)}
                    </div>
                  ) : (
                    <CustomInput
                      labelText="Schema"
                      id={`device-data_schema${idx}`}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: JSON.stringify(deviceType.data_schema),
                        // error: !this.isValidJson(
                        //   this.state.updatedavailable_status_types &&
                        //     this.state.updatedavailable_status_types[idx]
                        //       .data_schema
                        // ),
                        disabled: this.state.editDeviceTypeDetails !== true,
                        onChange: this.onDataChange(
                          "available_device_types",
                          idx,
                          "data_schema"
                        )
                      }}
                    />
                  )}
                </div>
                <div>
                  <CustomInput
                    labelText="Device Identifier Field"
                    id={`device-identifier-field-${idx}`}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: deviceType.identifier_field,
                      disabled: this.state.editDeviceTypeDetails !== true,
                      onChange: this.onDataChange(
                        "available_device_types",
                        idx,
                        "identifier_field"
                      )
                    }}
                  />
                </div>
              </div>

              <div className={classes.alarmSelectionContainer}>
                <Button
                  disabled={this.state.editDeviceTypeDetails !== true}
                  onClick={() => this.updateDeviceType()}
                >
                  Add
                </Button>
                <Button
                  onClick={() =>
                    this.setState({
                      visibleDeviceType: null,
                      editDeviceTypeDetails: false
                    })
                  }
                >
                  Cancel
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    );
  }

  renderDeviceTypes() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="info">
          <div className={classes.deviceTypeSelectorContainer}>
            <h4 className={classes.cardTitleWhite}>Device Types</h4>
            <IconButton
              onClick={() => this.setState({ deviceTypesVisible: true })}
              style={{
                display:
                  this.state.deviceTypesVisible !== true ? "block" : "none"
              }}
            >
              <Visibility />
            </IconButton>
            <IconButton
              onClick={() => this.setState({ deviceTypesVisible: false })}
              style={{
                display:
                  this.state.deviceTypesVisible === true ? "block" : "none"
              }}
            >
              <VisibilityOff />
            </IconButton>
          </div>
        </CardHeader>
        <CardBody>
          {this.state.deviceTypesVisible &&
            this.state.available_device_types &&
            this.state.available_device_types.map((deviceType, idx) => {
              return (
                <GridContainer
                  key={`device_type-${idx}`}
                  className={classes.deviceTypeContainer}
                >
                  <div
                    key={`device_type-selector-${idx}`}
                    className={classes.deviceTypeSelectorContainer}
                  >
                    <h6>{deviceType.name}</h6>
                    <div className={classes.alarmActions}>
                      <IconButton
                        onClick={() =>
                          this.setState({ visibleDeviceType: idx })
                        }
                        style={{
                          display:
                            this.state.visibleDeviceType !== idx
                              ? "block"
                              : "none"
                        }}
                      >
                        <Visibility />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          this.setState({ visibleDeviceType: null })
                        }
                        style={{
                          display:
                            this.state.visibleDeviceType === idx
                              ? "block"
                              : "none"
                        }}
                      >
                        <VisibilityOff />
                      </IconButton>
                    </div>
                  </div>
                  {this.state.visibleDeviceType === idx &&
                    this.renderDeviceType(deviceType, idx)}
                </GridContainer>
              );
            })}
          {this.state.deviceTypesVisible && (
            <div className={classes.alarmActions}>
              <IconButton onClick={() => this.addNewDeviceType()}>
                <AddIcon />
              </IconButton>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }

  renderStatusType(statusType, idx) {
    const { classes, user } = this.props;

    return (
      <div key={`status-type-${idx}`} className={classes.alarmsContainer}>
        <div className={classes.alarmActions}>
          <Modal
            hideBackdrop
            open={this.state.visibleStatusType === idx}
            onClose={() => this.setState({ visibleStatusType: null })}
            aria-labelledby="Add"
            aria-describedby="Add/Update status type"
          >
            <Box className={classes.alarmModalBox} sx={{ width: 400 }}>
              <div className={classes.alarmActions}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!(user.isAdmin === true)}
                      checked={this.state.editStatusTypeDetails === true}
                      onChange={() => {
                        this.setState({
                          editStatusTypeDetails: !this.state
                            .editStatusTypeDetails
                        });
                      }}
                    />
                  }
                  label="Edit"
                />
                <IconButton onClick={() => this.removeStatusType(idx)}>
                  <DeleteIcon />
                </IconButton>
              </div>
              <div
                key={`status_type-${idx}`}
                className={classes.deviceTypeContainer}
              >
                <div>
                  <label>Status Type</label>
                  <CustomSelect
                    className={classes.alarmTypeSelector}
                    labelText={statusType.target_type}
                    items={AVAILABLE_STATUS_TYPES}
                    disabled={this.state.editStatusTypeDetails !== true}
                    onChange={this.onSelectionChange(
                      "available_status_types",
                      idx,
                      "target_type"
                    )}
                  />
                </div>
                <div>
                  <CustomInput
                    labelText="Name"
                    id={`status_type-name-${idx}`}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: statusType.name,
                      disabled: this.state.editStatusTypeDetails !== true,
                      onChange: this.onDataChange(
                        "available_status_types",
                        idx,
                        "name"
                      )
                    }}
                  />
                </div>
                <div>
                  <label>Updated Trigger</label>
                  <CustomSelect
                    className={classes.alarmTypeSelector}
                    labelText={statusType.update_trigger}
                    items={AVAILABLE_STATUS_TRIGGER_TYPES}
                    disabled={this.state.editStatusTypeDetails !== true}
                    onChange={this.onSelectionChange(
                      "available_status_types",
                      idx,
                      "update_trigger"
                    )}
                  />
                </div>
                <div>
                  {this.state.editStatusTypeDetails !== true ? (
                    <div className={classes.rawTelemetryDataContainer}>
                      {prettyJson(statusType.translation_schema, classes)}
                    </div>
                  ) : (
                    <CustomInput
                      labelText="Schema"
                      id={`status_type-data_schema${idx}`}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: JSON.stringify(
                          statusType.translation_schema
                        ),
                        // error: !this.isValidJson(
                        //   this.state.updatedavailable_status_types &&
                        //     this.state.updatedavailable_status_types[idx]
                        //       .translation_schema
                        // ),
                        disabled: this.state.editStatusTypeDetails !== true,
                        onChange: this.onDataChange(
                          "available_status_types",
                          idx,
                          "translation_schema"
                        )
                      }}
                    />
                  )}
                </div>
              </div>

              <div className={classes.alarmSelectionContainer}>
                <Button
                  disabled={this.state.editStatusTypeDetails !== true}
                  onClick={() => this.updateStatusType()}
                >
                  Add
                </Button>
                <Button
                  onClick={() =>
                    this.setState({
                      visibleStatusType: null,
                      editStatusTypeDetails: false
                    })
                  }
                >
                  Cancel
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    );
  }

  renderStatusTypes() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="info">
          <div className={classes.deviceTypeSelectorContainer}>
            <h4 className={classes.cardTitleWhite}>Status Types</h4>
            <IconButton
              onClick={() => this.setState({ statusTypesVisible: true })}
              style={{
                display:
                  this.state.statusTypesVisible !== true ? "block" : "none"
              }}
            >
              <Visibility />
            </IconButton>
            <IconButton
              onClick={() => this.setState({ statusTypesVisible: false })}
              style={{
                display:
                  this.state.statusTypesVisible === true ? "block" : "none"
              }}
            >
              <VisibilityOff />
            </IconButton>
          </div>
        </CardHeader>
        <CardBody>
          {this.state.statusTypesVisible &&
            this.state.available_status_types &&
            this.state.available_status_types.map((statusType, idx) => {
              return (
                <GridContainer
                  key={`status_type-${idx}`}
                  className={classes.deviceTypeContainer}
                >
                  <div
                    key={`status_type-selector-${idx}`}
                    className={classes.deviceTypeSelectorContainer}
                  >
                    <h6>{statusType.name}</h6>
                    <div className={classes.alarmActions}>
                      <IconButton
                        onClick={() =>
                          this.setState({ visibleStatusType: idx })
                        }
                        style={{
                          display:
                            this.state.visibleStatusType !== idx
                              ? "block"
                              : "none"
                        }}
                      >
                        <Visibility />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          this.setState({ visibleStatusType: null })
                        }
                        style={{
                          display:
                            this.state.visibleStatusType === idx
                              ? "block"
                              : "none"
                        }}
                      >
                        <VisibilityOff />
                      </IconButton>
                    </div>
                  </div>
                  {this.state.visibleStatusType === idx &&
                    this.renderStatusType(statusType, idx)}
                </GridContainer>
              );
            })}
          {this.state.statusTypesVisible && (
            <div className={classes.alarmActions}>
              <IconButton onClick={() => this.addNewStatusType()}>
                <AddIcon />
              </IconButton>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardAvatar profile>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={`${MEDIA_ROOT}${this.state.avatar}`} alt="..." />
                </a>
              </CardAvatar>
              <CardBody profile>
                <h6 className={classes.cardCategory}>{this.state.roles}</h6>
                <h4 className={classes.cardTitle}>{this.state.first_name}</h4>
                <p className={classes.description}>{this.state.about_me}</p>
              </CardBody>
            </Card>
            <GridItem xs={12} sm={12} md={12}>
              {this.renderDeviceTypes()}
              {this.renderStatusTypes()}
            </GridItem>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>Edit Profile</h4>
                <p className={classes.cardCategoryWhite}>Profile details</p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Username"
                      id="username"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.username,
                        onChange: this.onChange("username")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Email address"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.email,
                        onChange: this.onChange("email")
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="First Name"
                      id="first_name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.first_name,
                        onChange: this.onChange("first_name")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Last Name"
                      id="last_name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.last_name,
                        onChange: this.onChange("last_name")
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="City"
                      id="city"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.city,
                        onChange: this.onChange("city")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Country"
                      id="country"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.country,
                        onChange: this.onChange("country")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Postal Code"
                      id="postal_code"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.postal_code,
                        onChange: this.onChange("postal_code")
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <InputLabel style={{ color: "#AAAAAA" }}>
                      About me
                    </InputLabel>
                    <CustomInput
                      labelText={""}
                      id="about_me"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.about_me,
                        onChange: this.onChange("about_me"),
                        multiline: true,
                        rows: 5
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <InputLabel style={{ color: "#AAAAAA" }}>Avatar</InputLabel>
                    <Input type="file" onChange={this.onFileLoad} />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <CustomInput
                    labelText="Data Publishing Token"
                    id="device_data_token"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.device_data_token,
                      disabled: true
                    }}
                  />
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="info" onClick={this.updateProfile}>
                  Update Profile
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(UserProfile);
