// @material-ui/icons
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import Reorder from "@material-ui/icons/Reorder";
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Chat from "@material-ui/icons/Chat";
// import ContentPaste from "@material-ui/icons/ContentPaste";
// core components/views
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import UserProfile from "views/UserProfile/UserProfile.jsx";
import ChatPage from "views/ChatPage/ChatPage.jsx";
// import CalendarPage from "views/Calendar/Calendar.jsx";
// import AppointmentPage from "views/BookAppointment/BookAppointment.jsx";
import MyUsers from "views/MyUsers/MyUsers.jsx";
import PlanPage from "views/Plan/Plan.jsx";
import QuizResponse from "views/QuizResponse/QuizResponse.jsx";

import { withAxios } from "containers/authenticated.js";

const advisorDashboardRoutes = [
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    component: withAxios(DashboardPage)
  },
  {
    path: "/myusers",
    sidebarName: "My Users",
    navbarName: "My Users",
    icon: Reorder,
    component: withAxios(MyUsers)
  },
  {
    path: "/chat",
    sidebarName: "Chat",
    navbarName: "Chat",
    icon: Chat,
    component: withAxios(ChatPage)
  },
  {
    path: "/user",
    sidebarName: "About Me",
    navbarName: "About Me",
    icon: Person,
    component: withAxios(UserProfile)
  },
  {
    path: "/healthplan/:user_id",
    sidebarName: "User health plans",
    navbarName: "User health plans",
    icon: Reorder,
    component: withAxios(PlanPage)
  },
  {
    path: "/quizresponse/",
    sidebarName: "Quiz Responses",
    navbarName: "Quiz Responses",
    icon: QuestionAnswer,
    component: withAxios(QuizResponse)
  },
  { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default advisorDashboardRoutes;
