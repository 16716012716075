/* eslint-disable react/prop-types */
import React from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import "./leaflet.css";
//import icon from 'images/marker-icon-2x.png';
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

/// variable para cargue de icono del marcador
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

//// estilo del container para el mapa
const styles = {
  wrapper: {
    height: 300,
    width: "100%",
    margin: "0",
    display: "flex"
  },
  map: {
    flex: 1,
    borderRadius: "20px"
  }
};

const Moves = props => {
  return (
    <div style={styles.wrapper}>
      {/* Constructor del mapa */}
      <Map style={styles.map} center={props.center} zoom={props.zoom}>
        {/* Capa con plantilla */}
        <TileLayer url={props.url} />
        {/* Marcador de posicionamiento */}
        {props.dataPoints.length > 0 &&
          props.dataPoints.map((dataPoint, idx) => (
            <Marker
              key={`data-point-${idx}`}
              position={[dataPoint.latitude, dataPoint.longitude]}
            >
              <Popup>
                {"Device: "}
                <span>{dataPoint.name}</span>
              </Popup>
            </Marker>
          ))}
      </Map>
    </div>
  );
};

//// Propiedades del mapa
Moves.defaultProps = {
  center: [52.5103175, 13.3275342],
  zoom: 5,
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
};

export default Moves;
