import { API_ROOT } from "config.js";

const USER_HEALTH_PLAN_ENDPOINT = `${API_ROOT}/api/health/userplans/`;
const USER_RECOMMENDATIONS_ENDPOINT = `${API_ROOT}/api/health/recommendations/`;
const HEALTH_LIBRARY_ENDPOINT = `${API_ROOT}/api/health/library/`;

export async function getUserPlan(axios) {
  const response = await axios.get(`${USER_HEALTH_PLAN_ENDPOINT}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getUserRecommendations(axios) {
  const response = await axios.get(`${USER_RECOMMENDATIONS_ENDPOINT}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getPosts(axios) {
  const response = await axios.get(`${HEALTH_LIBRARY_ENDPOINT}posts`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
