import axios from "axios";
import { API_ROOT } from "config.js";

import { setAccessToken, removeAccessToken } from "containers/authenticated.js";

const AUTHORIZATION_ENDPOINT = `${API_ROOT}/api/api-token-auth/`;
const SIGNUP_ENDPOINT = `${API_ROOT}/api/api-token-auth/register/`;

export function login(username, password) {
  return axios
    .post(AUTHORIZATION_ENDPOINT, {
      username: username,
      password: password
    })
    .then(response => {
      const data = response.data;
      setAccessToken(data);
      return data;
    });
}

export function signup(userData) {
  return axios
    .post(SIGNUP_ENDPOINT, userData)
    .then(response => {
      setAccessToken(response.data);
      return {
        success: true,
        data: response.data
      };
    })
    .catch(error => {
      return {
        success: false,
        data: error.response.data
      };
    });
}

export function verifyLogin() {
  return axios
    .get(AUTHORIZATION_ENDPOINT, {
      withCredentials: true
    })
    .then(response => {
      const data = response.data;
      setAccessToken(data);
      return true;
    })
    .catch(error => {
      return false;
    });
}

export async function logout() {
  removeAccessToken();
}

export async function createGuest(searchPath) {
  let signup_url = SIGNUP_ENDPOINT;
  if (searchPath) {
    signup_url = `${SIGNUP_ENDPOINT}${searchPath}`;
  }
  return axios
    .get(signup_url)
    .then(response => {
      setAccessToken(response.data);
      return {
        success: true,
        data: response.data
      };
    })
    .catch(error => {
      return {
        success: false,
        data: error.response.data
      };
    });
}
