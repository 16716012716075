/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import MyLocation from "@material-ui/icons/MyLocation";
import FavoriteIcon from "@material-ui/icons/Favorite";
import WifiIcon from "@material-ui/icons/Wifi";
import AddIcon from "@material-ui/icons/Add";
import SignalWifiOff from "@material-ui/icons/SignalWifiOff";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

import MapLeft from "components/MapLeft/MapLeft.jsx";

import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { convertIpToDecimal } from "../../utils/converters";

import {
  DEVICE_TYPES,
  AVAILABLE_DEVICE_TYPES
} from "../../utils/deviceTypes.jsx";

import {
  getUserWidgets,
  getUserViews,
  getUserDevices,
  markDeviceFavorite,
  unmarkDeviceFavorite,
  createUserDevice,
  removeUserDevice,
  downloadDeviceData,
} from "../../services/dashboardDataProvider";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import deviceDefaultIcon from "assets/img/device-default.png";
import { MEDIA_ROOT } from "config.js";
import {
  dayjs
} from "utils/time.jsx";

import "./Device.css";

let anchorEl = null;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      favoriteDevices: [],
      addDeviceModalOpen: false,
      deviceAlias: null,
      deviceType: null
    };
    this.markDeviceAsFavorite = this.markDeviceAsFavorite.bind(this);
  }

  setAnchorEl(el) {
    anchorEl = el;
  }

  handleMenuClick = idx => (event: React.MouseEvent<HTMLElement>) => {
    this.setAnchorEl(event.currentTarget);
    this.setState({ selectedDev: idx })
  };

  onChange = (dictName, propName) => e => {
    const value = e.target.value;
    if (dictName == null || dictName === "") {
      this.setState({ [propName]: value });
    } else {
      let updatedData = this.state[dictName] || {};
      updatedData[propName] = value;
      this.setState({ [dictName]: updatedData });
    }
  };

  onSelectionChange = (dictName, propName) => value => {
    if (dictName == null || dictName === "") {
      this.setState({ [propName]: value });
    } else {
      let updatedData = this.state[dictName] || {};
      updatedData[propName] = value;
      this.setState({ [dictName]: updatedData });
    }
  };

  removeDevice = idx => {
    const devId = this.state.devices && this.state.devices[idx].id;
    if (window.confirm("Delete device ?")) {
      removeUserDevice(this.props.axios, devId).then(() => {
        this.fetchUserDevices();
      });
    }
  };

  downloadDeviceData() {
    let payload = this.state.downloadData || {};
    payload["exportType"] = "csv";
    downloadDeviceData(this.props.axios, "all", payload).then(data => {
      const blob = new Blob([data], { type: "text/csv" });
      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob);
      // Creating an anchor(a) tag of HTML
      const a = document.createElement("a");

      // Passing the blob downloading url
      a.setAttribute("href", url);

      // Setting the anchor tag attribute for downloading
      // and passing the download file name
      a.setAttribute("download", "all-devices-raw-data.csv");

      // Performing a download with click
      a.click();
      this.setState({ dataDownloadModalOpen: false });
    });
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    getUserViews(this.props.axios, "List").then(data => {
      if (data.length > 0) {
        let viewConfig = {};
        data.forEach(dt => {
          if (dt.metadata && dt.metadata.device_list_view) {
            viewConfig = dt.metadata.device_list_view;
          }
        });
        this.setState({ viewConfig: viewConfig });
      }
    });
    this.fetchUserDevices();
    this.getFavoriteDevices();
  }

  fetchUserDevices() {
    getUserDevices(this.props.axios).then(data => {
      this.setState({
        devices: data.devices
          .map(dev => {
            return { ...dev, ipNum: convertIpToDecimal(dev.ip_address) };
          })
          .sort((d1, d2) => d1.ipNum - d2.ipNum)
      });
    });
  }

  addNewDevice() {
    const data = {
      device_name: this.state.deviceAlias,
      device_type: this.state.deviceType
    };
    createUserDevice(this.props.axios, data).then(_ => {
      this.setState({ addDeviceModalOpen: false });
      this.fetchUserDevices();
    });
  }

  getFavoriteDevices() {
    getUserWidgets(this.props.axios).then(data => {
      let favoriteWidget = data.results.find(
        widgetData => widgetData.widget.name === "Favorite Devices"
      );
      const favoriteDevices =
        (favoriteWidget &&
          favoriteWidget.metadata &&
          favoriteWidget.metadata.favorite_devices) ||
        [];
      this.setState({ favoriteDevices: favoriteDevices });
    });
  }

  markDeviceAsFavorite = (device, markFavorite) => e => {
    if (markFavorite) {
      markDeviceFavorite(this.props.axios, device.ip_address).then(_ =>
        this.getFavoriteDevices()
      );
    } else {
      unmarkDeviceFavorite(this.props.axios, device.ip_address).then(_ =>
        this.getFavoriteDevices()
      );
    }
  };

  renderDeviceCompact(deviceData, idx) {
    const { classes } = this.props;
    const timeNow = dayjs();
    const lastDataSyncTime = deviceData.other_data &&  deviceData.other_data.last_data_sync_time;
    const isDeviceActive =
      lastDataSyncTime &&
      timeNow.diff(dayjs(lastDataSyncTime), "days") < 1
        ? true
        : false;
    const hasNotifications = false;

    const isFavoriteDev = this.state.favoriteDevices.includes(deviceData.id);

    const deviceType = deviceData.type;

    let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
      <div className="deviceCompactViewContainer">
        <div className="deviceDetailsContainer">
          <div>
            Type: <b>{deviceData.type}</b>
          </div>
          <div>
            Last Updated:{" "}
            <b>{lastDataSyncTime ? dayjs(lastDataSyncTime).format("D-MM-YYYY, hh:mm") : "Unknown"}</b>
            <small>({tz})</small>
          </div>
        </div>
        <div className="deviceDetailsIconsContainer">
          <div className="deviceActionsContainer">
            <h6 className="deviceAlias">{deviceData.alias}</h6>
            <IconButton className="deviceActionsMenuButton">
              <MoreVertIcon
                onClick={this.handleMenuClick(idx)}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={this.state.selectedDev === idx}
              onClose={() => this.setState({ selectedDev: null })}
            >
              <MenuItem onClick={() => this.removeDevice(idx)}>Remove</MenuItem>
            </Menu>
          </div>
          <div className="devStatusIconsContainer">
            <FavoriteIcon
              className="devStatusIcon"
              color={isFavoriteDev === true ? "primary" : "disabled"}
              onClick={this.markDeviceAsFavorite(
                deviceData,
                isFavoriteDev === false
              )}
            />
            {isDeviceActive ? (
              <WifiIcon className="devStatusIcon" color="primary" />
            ) : (
              <SignalWifiOff className="devStatusIcon" color="disabled" />
            )}
            {hasNotifications ? (
              <NotificationsActiveIcon
                className="devStatusIcon"
                color="primary"
              />
            ) : (
              <NotificationsIcon className="devStatusIcon" color="disabled" />
            )}
          </div>
        </div>
        <div className="actionButtonsContainer">
          <Button
            round={true}
            size="sm"
            color="info"
            className={classes.marginRight}
            onClick={e =>
              this.props.history.push(`devicedetails/${deviceData.ip_address}`)
            }
          >
            Details
          </Button>
          <Button
            round={true}
            size="sm"
            color="info"
            className={classes.marginRight}
            disabled={!Object.keys(DEVICE_TYPES).includes(deviceType)}
            onClick={e =>
              this.props.history.push(`devicereports/${deviceData.ip_address}`)
            }
          >
            Report
          </Button>
        </div>
      </div>
    );
  }

  renderDevice(deviceData, idx) {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    const timeNow = dayjs();
    const lastDataSyncTime = deviceData.other_data &&  deviceData.other_data.last_data_sync_time;
    const isDeviceActive =
      lastDataSyncTime &&
      timeNow.diff(dayjs(lastDataSyncTime), "days") < 1
        ? true
        : false;
    const hasNotifications = false;

    const isFavoriteDev = this.state.favoriteDevices.includes(deviceData.id);

    let deviceAvatar = deviceDefaultIcon;
    if (deviceData.face != null) {
      deviceAvatar = `${MEDIA_ROOT}${deviceData.face}`;
    }
    let deviceType = deviceData.type;

    return (
      <Card>
        <CardHeader color="info" stats icon>
          <CardIcon color="info" className={classes.deviceSummaryCardIcon}>
            <img src={deviceAvatar} alt="face" width="200px" height="150px" />
          </CardIcon>
          <div className={classes.cardCategory}>
            <h6 className="deviceAlias">{deviceData.alias}</h6>
          </div>
          <div className="deviceDetailsIconsContainer">
            <div className="devStatusIconsContainer">
              <FavoriteIcon
                className="devStatusIcon"
                color={isFavoriteDev === true ? "primary" : "disabled"}
                onClick={this.markDeviceAsFavorite(
                  deviceData,
                  isFavoriteDev === false
                )}
              />
              {isDeviceActive ? (
                <WifiIcon className="devStatusIcon" color="primary" />
              ) : (
                <SignalWifiOff className="devStatusIcon" color="disabled" />
              )}
              {hasNotifications ? (
                <NotificationsActiveIcon
                  className="devStatusIcon"
                  color="primary"
                />
              ) : (
                <NotificationsIcon className="devStatusIcon" color="disabled" />
              )}
              <IconButton className="deviceActionsMenuButton">
                <MoreVertIcon
                  className="deviceActionsMenuIcon"
                  onClick={this.handleMenuClick(idx)}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={this.state.selectedDev === idx}
                onClose={() => this.setState({ selectedDev: null })}
              >
                <MenuItem onClick={() => this.removeDevice(idx)}>Remove</MenuItem>
              </Menu>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="deviceDetailsContainer">
            <div>
              IP Address: <b>{deviceData.ip_address}</b>
            </div>
            <div>
              Type: <b>{deviceData.type}</b>
            </div>
            <div>
              Last Updated:{" "}
              <b>
                {lastDataSyncTime ? dayjs(lastDataSyncTime).format("D-MM-YYYY, hh:mm") : "Unknown"}
              </b>
            </div>
          </div>
        </CardBody>
        <CardFooter stats>
          <GridContainer>
            <div className={"deviceLocationContainer"} xs={12} sm={12} md={12}>
              <MyLocation /> {deviceData.address}
            </div>
            <div className="actionButtonsContainer">
              <Button
                color="info"
                size="sm"
                className={classes.marginRight}
                onClick={e =>
                  this.props.history.push(
                    `devicedetails/${deviceData.ip_address}`
                  )
                }
              >
                Details
              </Button>
              <Button
                color="info"
                size="sm"
                className={classes.marginRight}
                disabled={!Object.keys(DEVICE_TYPES).includes(deviceType)}
                onClick={e =>
                  this.props.history.push(
                    `devicereports/${deviceData.ip_address}`
                  )
                }
              >
                Report
              </Button>
            </div>
          </GridContainer>
        </CardFooter>
      </Card>
    );
  }

  renderDevices() {
    const { viewConfig } = this.state;
    const isCompactView = viewConfig && viewConfig.compact === true;
    return (
      <GridContainer>
        {this.state.devices &&
          this.state.devices.map((deviceData, idx) => {
            return (
              <GridItem xs={12} sm={12} md={6} key={`widget-${deviceData.id}`}>
                {!isCompactView
                  ? this.renderDevice(deviceData, idx)
                  : this.renderDeviceCompact(deviceData, idx)}
              </GridItem>
            );
          })}
      </GridContainer>
    );
  }

  median(values) {
    if (values.length === 0) {
      throw new Error('Input array is empty');
    }

    // Sorting values, preventing original array
    // from being mutated.
    values = [...values].sort((a, b) => a - b);
  
    const half = Math.floor(values.length / 2);
  
    return (values.length % 2
      ? values[half]
      : (values[half - 1] + values[half]) / 2
    );
  }

  renderDevicesMap() {
    let zoom = 2;
    let center = [52.5103175, 13.3275342];
    let minLat = null, minLong = null;
    let maxLat = null, maxLong = null;
    let dataPoints = [];
    this.state.devices &&
      this.state.devices.forEach(deviceData => {
        if (deviceData.lat && deviceData.long) {
          let lat = parseFloat(deviceData.lat);
          let lng = parseFloat(deviceData.long);
          dataPoints.push({
            latitude: lat,
            longitude: lng,
            name: deviceData.alias,
            url: deviceData.url
          });
          if (minLat == null || lat < minLat) {
            minLat = lat;
          }
          if (minLong == null || lng < minLong) {
            minLong = lng;
          }
          if (maxLat == null || lat > maxLat) {
            maxLat = lat;
          }
          if (maxLong == null || lng > maxLong) {
            maxLong = lng;
          }
        }
      });
    if (dataPoints.length > 0) {
      center = [
        this.median(dataPoints.map(dp => dp.latitude)),
        this.median(dataPoints.map(dp => dp.longitude)),
      ];
      zoom = (Math.abs(maxLat - minLat) + Math.abs(maxLong - minLong));
      zoom = (zoom * 7) % 13;
    }
    return (
      <GridContainer>
        <MapLeft center={center} dataPoints={dataPoints} zoom={zoom} />
      </GridContainer>
    );
  }

  renderDeviceCreation() {
    const { classes } = this.props;
    let availableStatusTypes = [{ value: "raw_data", text: "Raw Data" }];
    const downloadData = this.state.downloadData || {};
    return (
      <div className="addDeviceButtonContainer">
        <Button
          color="info"
          size="sm"
          className={classes.marginRight}
          onClick={e => this.setState({ addDeviceModalOpen: true })}
        >
          <AddIcon className="devStatusIcon" color="warning" /> Add new device
        </Button>
        <Button
            color="info"
            size="sm"
            className={classes.marginRight}
            onClick={() => this.setState({ dataDownloadModalOpen: true })}
          >
            Download Data
          </Button>
        <Modal
          hideBackdrop
          open={this.state.addDeviceModalOpen}
          onClose={e => this.setState({ addDeviceModalOpen: false })}
          aria-labelledby="Add"
          aria-describedby="Add new device"
        >
          <Box className="deviceModalBox">
            <div className="deviceSelectionContainer">
              <span className={classes.chartTypeLabel}>Device Name</span>
              <CustomInput
                className="deviceInputField"
                key="dev-alias-input"
                id="alias"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.deviceAlias || "",
                  onChange: this.onChange(null, "deviceAlias")
                }}
              />
            </div>
            <div className="deviceSelectionContainer">
              <span className={classes.chartTypeLabel}>Device Type</span>
              <CustomSelect
                className="deviceInputField"
                items={AVAILABLE_DEVICE_TYPES}
                onChange={this.onSelectionChange(null, "deviceType")}
              />
            </div>
            <div className="deviceSelectionContainer">
              <Button
                color="info"
                size="sm"
                disabled={!(this.state.deviceType && this.state.deviceAlias)}
                onClick={e => this.addNewDevice()}
              >
                Add
              </Button>
              <Button
                size="sm"
                onClick={e => this.setState({ addDeviceModalOpen: false })}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
        <Modal
            hideBackdrop
            open={this.state.dataDownloadModalOpen}
            onClose={() => this.setState({ dataDownloadModalOpen: false })}
            aria-labelledby="Download"
            aria-describedby="Download Data"
          >
            <Box className="deviceModalBox">
              <div className="deviceSelectionContainer">
                <label className={classes.chartTypeLabel}>Data Type</label>
                <CustomSelect
                  className={classes.alarmTypeSelector}
                  labelText={this.state.selectedAlarmType}
                  items={availableStatusTypes}
                  onChange={this.onSelectionChange("downloadData", "dataType")}
                />
              </div>
              <div className="deviceSelectionContainer">
                <CustomInput
                  labelText="Start Date"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "date",
                    value: downloadData.startDate,
                    onChange: this.onChange("downloadData", "startDate")
                  }}
                />
              </div>
              <div className="deviceSelectionContainer">
                <CustomInput
                  labelText="End Date"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "date",
                    value: downloadData.endDate,
                    onChange: this.onChange("downloadData", "endDate")
                  }}
                />
              </div>
              <div className="deviceSelectionContainer">
                <Button size="sm" onClick={() => this.downloadDeviceData()}>
                  Download
                </Button>
                <Button
                  size="sm"
                  onClick={() =>
                    this.setState({ dataDownloadModalOpen: false })
                  }
                >
                  Cancel
                </Button>
              </div>
            </Box>
          </Modal>
      </div>
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {this.renderDevicesMap()}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {this.renderDeviceCreation()}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {this.renderDevices()}
        </GridItem>
      </GridContainer>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
