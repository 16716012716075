import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// import Datetime from "react-datetime";
// @material-ui/core
import Assignment from "@material-ui/icons/Assignment";
import withStyles from "@material-ui/core/styles/withStyles";
// import TextField from "@material-ui/core/TextField";

// import FormControl from "@material-ui/core/FormControl";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import CommentBox from "components/CommentBox/CommentBox.jsx";
import Comment from "components/Comment/Comment.jsx";

import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

import {
  getAnswers,
  updateAnswers,
  getUserForms,
  getMyUsers
} from "../../services/advisorUsers.js";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class QuizResponse extends React.Component {
  state = {
    value: 0,
    users: []
  };

  ftechAnswers() {
    if (this.state.userId && this.state.formId) {
      getAnswers(
        // eslint-disable-next-line react/prop-types
        this.props.axios,
        this.state.userId,
        this.state.formId,
        this.state.day
      ).then(data => {
        this.setState({ answersData: data });
      });
    }
  }

  handleChange = name => value => {
    let { userId, formId, day, quizzes } = this.state;
    if (name === "day") {
      day = value.format();
    } else if (name === "user") {
      userId = value;
      quizzes = [];
      getUserForms(this.props.axios, value, true).then(data => {
        this.setState({ quizzes: data }, dt => this.ftechAnswers());
      });
    } else if (name === "quiz") {
      formId = value;
    }
    this.setState(
      { userId: userId, formId: formId, day: day, quizzes: quizzes },
      dt => this.ftechAnswers()
    );
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    getMyUsers(this.props.axios).then(data => {
      this.setState({ users: data });
    });
  }

  handleCommentChange = idx => e => {
    let comments = this.state.comments || {};
    comments[idx] = e.target.value;
    this.setState({ comments: comments });
  };

  getAnswer(data) {
    let answer = "";
    data &&
      Object.keys(data).forEach(key => {
        let answerVal = data[key];
        if (key !== "feedback") {
          if (key === "-data") {
            answerVal = answerVal.replace("-data", "");
            let dateVal = moment(answerVal).format("LLL");
            if (dateVal !== "Invalid date") {
              answerVal = dateVal;
            }
          } else if (answerVal === true) {
            answerVal = key;
          } else {
            answerVal = `${key}: ${answerVal}`;
          }
          answer = `${answerVal}, ${answer}`;
        }
      });
    return answer;
  }

  addComment = answerId => (name, comment) => {
    let commentData = {
      answer_id: answerId,
      comment: comment
    };
    updateAnswers(
      this.props.axios,
      this.state.userId,
      this.state.formId,
      commentData
    ).then(data => {
      alert("Submitted Successfully");
      this.setState({ answersData: data });
    });
  };

  renderUsers() {
    const { classes } = this.props;
    let answers =
      (this.state.answersData && this.state.answersData.answers) || [];
    const formData = this.state.answersData && this.state.answersData.form;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Users</h4>
            </CardHeader>
            <CardBody>
              {this.renderInputs()}
              <h3>{formData && formData.name}</h3>
              <Table
                tableHeaderColor="primary"
                tableData={answers.map((answerData, idx) => {
                  return [this.renderComments(idx, answerData)];
                })}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  renderInputs() {
    let userOptions =
      this.state.users &&
      this.state.users.map(user => {
        return { text: user.username, value: user.id };
      });
    let quizOptions =
      this.state.quizzes &&
      this.state.quizzes.map(quiz => {
        return { text: quiz.form.name, value: quiz.form.id };
      });
    return (
      <GridContainer>
        {/* <GridItem xs={12} sm={12} md={4}>
          <FormControl fullWidth>
            <Datetime
              timeFormat={false}
              inputProps={{ placeholder: "Day" }}
              onChange={this.handleChange("day")}
            />
          </FormControl>
        </GridItem> */}
        <GridItem xs={12} sm={12} md={4}>
          <CustomSelect
            labelText={"User"}
            items={userOptions}
            onChange={this.handleChange("user")}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomSelect
            labelText={"Quiz"}
            items={quizOptions}
            onChange={this.handleChange("quiz")}
          />
        </GridItem>
      </GridContainer>
    );
  }

  renderComments(idx, answerData) {
    const answer = {
      user_name: "answer",
      comment: this.getAnswer(
        answerData.answer_details && answerData.answer_details.answer
      )
    };
    const comments =
      (answerData.answer_details && answerData.answer_details.comments) || [];
    return (
      <div>
        <h6>{answerData.question.question}</h6>
        <Comment comment={answer} />
        {comments.map((comment, idx) => {
          if (comment.user_name === this.props.user.user_name) {
            comment.user_name = "you";
          }
          return <Comment key={`comment-${idx}`} comment={comment} />;
        })}
        <CommentBox title={""} addComment={this.addComment(answerData.id)} />
      </div>
    );
  }

  render() {
    return <div>{this.renderUsers()}</div>;
  }
}

QuizResponse.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(QuizResponse);
