import React, { Component } from "react";

class Comment extends Component {
  render() {
    console.log("Comment", this.props.comment);
    return (
      <article className="media">
        <figure className="media-left">
          <p className="image is-64x64">
            {/* <img
              src="https://bulma.io/images/placeholders/128x128.png"
              alt="Avatar"
            /> */}
          </p>
        </figure>
        <div className="media-content">
          <div className="content">
            <p>
              <strong>{this.props.comment.user_name}</strong>
              <br />
              {this.props.comment.comment}
            </p>
          </div>
        </div>
      </article>
    );
  }
}

export default Comment;