// @material-ui/icons
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import LocalLibrary from "@material-ui/icons/LocalLibrary";
import Reorder from "@material-ui/icons/Reorder";
import Class from "@material-ui/icons/Class";
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
// import Chat from "@material-ui/icons/Chat";
// import ContentPaste from "@material-ui/icons/ContentPaste";
// core components/views
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import DevicesPage from "views/Devices/Devices.jsx";
import DeviceDetailsPage from "views/DeviceDetails/DeviceDetails.jsx";
import DeviceReportsPage from "views/DeviceReports/DeviceReports.jsx";
import UserProfile from "views/UserProfile/UserProfile.jsx";
// import QuizList from "views/QuizListing/QuizListing.jsx";
// import QuizResults from "views/QuizResults/QuizResults.jsx";
// import ChatPage from "views/ChatPage/ChatPage.jsx";
// import CalendarPage from "views/Calendar/Calendar.jsx";
// import LibraryPage from "views/Library/Library.jsx";
// import AppointmentPage from "views/BookAppointment/BookAppointment.jsx";
import PlanPage from "views/PlanUser/Plan.jsx";
// import QuizResponseUser from "views/QuizResponseUser/QuizResponseUser.jsx";

import { withAxios } from "containers/authenticated.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    component: withAxios(DashboardPage)
  },
  {
    path: "/mydevices",
    sidebarName: "My Devices",
    navbarName: "My Devices",
    icon: Class,
    component: withAxios(DevicesPage)
  },
  {
    path: "/myplan",
    sidebarName: "My Plan",
    navbarName: "My Plan",
    icon: Reorder,
    component: withAxios(PlanPage)
  },
  {
    path: "/user",
    sidebarName: "About Me",
    navbarName: "About Me",
    icon: Person,
    component: withAxios(UserProfile)
  },
  {
    path: "/devicedetails/:deviceId",
    sidebarName: "Device Details",
    navbarName: "Device Details",
    icon: QuestionAnswer,
    component: withAxios(DeviceDetailsPage),
    hide: true
  },
  {
    path: "/devicereports/:deviceId",
    sidebarName: "Device Reports",
    navbarName: "Device Reports",
    icon: LocalLibrary,
    component: withAxios(DeviceReportsPage),
    hide: true
  },
  // {
  //   path: "/mylivinglibrary",
  //   sidebarName: "My Living Library",
  //   navbarName: "My Living Library",
  //   icon: LocalLibrary,
  //   component: withAxios(LibraryPage)
  // },
  // {
  //   path: "/chat",
  //   sidebarName: "Chat",
  //   navbarName: "Chat",
  //   icon: Chat,
  //   component: withAxios(ChatPage)
  // },
  // {
  //   path: "/quizresponse/:quizName",
  //   sidebarName: "Quiz Responses",
  //   navbarName: "Quiz Responses",
  //   icon: QuestionAnswer,
  //   component: withAxios(QuizResponseUser),
  //   hidden: true
  // },
  // {
  //   path: "/quiz/:quizName",
  //   sidebarName: "Quiz",
  //   navbarName: "Quiz",
  //   icon: QuestionAnswer,
  //   component: withAxios(QuizList),
  //   hide: true
  // },
  // {
  //   path: "/results/:quizName",
  //   sidebarName: "Results",
  //   navbarName: "Results",
  //   icon: QuestionAnswer,
  //   component: withAxios(QuizResults),
  //   hide: true
  // },
  // {
  //   path: "/scheduleappointment",
  //   sidebarName: "Schedule Appointment",
  //   navbarName: "Schedule Appointment",
  //   icon: QuestionAnswer,
  //   component: withAxios(AppointmentPage),
  //   hide: true
  // },
  { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default dashboardRoutes;
