import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";

import {
  getUserPlan,
  getUserRecommendations
} from "../../services/planDataProvider";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

let momentObj = moment();
momentObj.format("LLLL");

class Plan extends React.Component {
  state = {
    value: 0,
    userplans: []
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    getUserPlan(this.props.axios).then(data => {
      this.setState({ userplans: data.results });
    });

    getUserRecommendations(this.props.axios).then(data => {
      this.setState({ recommendations: data.results });
    });
  }

  handleClick = (name, idx) => e => {
    console.log(name, idx, e);
  };

  renderPlan(planData) {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    if (planData.plan === undefined) {
      return null;
    }
    return (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{planData.plan.name}</h4>
          <p className={classes.cardCategoryWhite}>{`Available sessions ${
            planData.available_sessions
          }/${planData.total_sessions}`}</p>
        </CardHeader>
        <CardBody>
          <Table
            tableHeaderColor="primary"
            // tableHead={["ID", "Name", "Salary", "Country"]}
            tableData={[
              [
                "Plan start day",
                moment(planData.start_time, "YYYY-MM-DDTh:mm:ssZ").format(
                  "MMMM Do YYYY"
                )
              ],
              [
                "Plan expiry day",
                moment(planData.end_time, "YYYY-MM-DDTh:mm:ssZ").format(
                  "MMMM Do YYYY"
                )
              ],
              [
                "Avialable sessions",
                `${planData.available_sessions} of ${planData.total_sessions}`
              ],
              [
                "Avialable session time",
                `${planData.avialable_sessions_time} of ${
                  planData.total_sessions_time
                }`
              ]
            ]}
          />
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            onClick={this.handleClick("details", planData.plan.id)}
          >
            See Details
          </Button>
          <Button
            color="primary"
            onClick={this.handleClick("cancel", planData.plan.id)}
          >
            Change Plan
          </Button>
        </CardFooter>
      </Card>
    );
  }

  renderAdvices(advices) {
    // const { classes } = this.props;
    // console.log("advices", advices);
    return advices.map(advice => {
      return advice.details.length > 0 && this.renderAdvice(advice);
    });
  }

  renderAdvice(advice) {
    // const { classes } = this.props;
    return (
      <div>
        <h6>{advice.name}</h6>
        <Table
          tableData={advice.details.map(adviceDetail => {
            return [adviceDetail.name, adviceDetail.description];
          })}
        />
      </div>
    );
  }

  renderRecommendation(recommData) {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    // console.log("recommData", recommData);
    if (recommData.recommendation_type === undefined) {
      return null;
    }
    let detailsString = (recommData.details && recommData.details.details);
    if (typeof detailsString === "object") {
      detailsString = "";
    }
    return (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>
            {`Health Recommendation: ${recommData.recommendation_type}`}
          </h4>
          <p className={classes.cardCategoryWhite}>{`Advisor: ${
            recommData.advisor
          }`}</p>
        </CardHeader>
        <CardBody>
          <Table
            tableHeaderColor="primary"
            // tableHead={["ID", "Name", "Salary", "Country"]}
            tableData={[
              [
                "Start day",
                moment(recommData.start_time, "YYYY-MM-DDTh:mm:ssZ").format(
                  "MMMM Do YYYY"
                )
              ],
              [
                "Expiry day",
                moment(recommData.end_time, "YYYY-MM-DDTh:mm:ssZ").format(
                  "MMMM Do YYYY"
                )
              ],
              ["Details", detailsString]
            ]}
          />
          {this.renderAdvices(recommData.health_advices)}
        </CardBody>
        {/* <CardFooter>
          <Button
            color="primary"
            onClick={this.handleClick("details", recommData.id)}
          >
            See Details
          </Button>
        </CardFooter> */}
      </Card>
    );
  }

  renderUserPlans() {
    let userPlans = this.state.userplans;
    return (
      <GridContainer>
        {userPlans.map((planData, idx) => {
          return (
            <GridItem xs={12} sm={12} md={12} key={`session-${idx}`}>
              {this.renderPlan(planData)}
            </GridItem>
          );
        })}
      </GridContainer>
    );
  }

  renderUserRecommendations() {
    let recommendations = this.state.recommendations || [];
    return (
      <GridContainer>
        {recommendations.map((recommData, idx) => {
          return (
            <GridItem xs={12} sm={12} md={12} key={`recommendation-${idx}`}>
              {this.renderRecommendation(recommData)}
            </GridItem>
          );
        })}
      </GridContainer>
    );
  }

  rendarCalendar() {
    return null;
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    return (
      <div>
        {this.renderUserPlans()}
        {this.renderUserRecommendations()}
        {this.rendarCalendar()}
      </div>
    );
  }
}

Plan.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Plan);
