import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import MyLocation from "@material-ui/icons/MyLocation";
import FavoriteIcon from "@material-ui/icons/Favorite";
import WifiIcon from "@material-ui/icons/Wifi";
import SignalWifiOff from "@material-ui/icons/SignalWifiOff";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { CircularProgressbar } from "react-circular-progressbar";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

import Chart from "components/Chart/ECharts.jsx";

import {
  getUserWidgets,
  getUserDevices,
  markDeviceFavorite,
  unmarkDeviceFavorite
} from "../../services/dashboardDataProvider";

import { MEDIA_ROOT } from "config.js";
import { dayjs, DATE_DISPLAY_FORMAT_DETAILED } from "utils/time.jsx";

const dashboardStyle = {
  statusSummaryContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap"
  },
  statusSummaryItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    maxWidth: "170px"
  },
  StatusIndicatorWidgetAcnhor: {
    width: "100%"
  },
  StatusSummaryItemTitle: {
    minWidth: "130px"
  },
  StatusSummaryItemWidget: {
    width: "auto",
    minWidth: "130px"
  },
  ExternalDashboardContainer: {
    width: "100%"
  },
  ExternalDashboardTitle: {
    width: "100%",
    color: "#235894",
    fontSize: "x-large",
    paddingTop: "25px",
    paddingBottom: "25px"
  },
  ExternalDashboardROW: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "warp",
    width: "100%"
  },
  ExternalDashboardChart: {
    width: "100%"
  },
  ExternalDashboardChartTitle: {
    width: "100%",
    color: "#235894",
    fontSize: "x-large",
    paddingBottom: "25px"
  }
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widgets: [],
      devices: []
    };
    this.markDeviceAsFavorite = this.markDeviceAsFavorite.bind(this);
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    getUserWidgets(this.props.axios).then(data =>
      this.setState({ widgets: data.results })
    );
    getUserDevices(this.props.axios).then(data => {
      this.setState({
        devices: data.devices
      });
    });
  }

  markDeviceAsFavorite = (device, markFavorite) => e => {
    if (markFavorite) {
      markDeviceFavorite(this.props.axios, device.ip_address).then(_ =>
        getUserWidgets(this.props.axios).then(data =>
          this.setState({ widgets: data.results })
        )
      );
    } else {
      unmarkDeviceFavorite(this.props.axios, device.ip_address).then(_ =>
        getUserWidgets(this.props.axios).then(data =>
          this.setState({ widgets: data.results })
        )
      );
    }
  };

  renderStatusSummary() {
    const { classes } = this.props;
    const widgets = this.state.widgets || [];
    let summaryWidget = widgets.filter(
      widgetData => widgetData.widget.type === "summary"
    );

    if (summaryWidget.length === 0) return null;

    let summaryWidgetData = summaryWidget[0].widget.metadata;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} key={`summaryWidgetData`}>
          <div className={classes.statusSummaryContainer}>
            <div className={classes.statusSummaryItem}>
              <h4 className={classes.StatusSummaryItemTitle}>Active devices</h4>
              <a
                href="/mydevices"
                className={classes.StatusIndicatorWidgetAcnhor}
              >
                <CircularProgressbar
                  className={classes.StatusSummaryItemWidget}
                  value={(
                    (summaryWidgetData.active_devices * 100) /
                    summaryWidgetData.total_devices
                  ).toFixed(1)}
                  text={`${summaryWidgetData.active_devices}/${
                    summaryWidgetData.total_devices
                  }`}
                />
              </a>
            </div>
            <div className={classes.statusSummaryItem}>
              <h4 className={classes.StatusSummaryItemTitle}>
                System Efficiency
              </h4>
              <a
                href="/systemefficiency"
                className={classes.StatusIndicatorWidgetAcnhor}
              >
                <CircularProgressbar
                  className={classes.StatusSummaryItemWidget}
                  value={(
                    (summaryWidgetData.active_devices * 100) /
                    summaryWidgetData.total_devices
                  ).toFixed(1)}
                  text={`${summaryWidgetData.active_devices}/${
                    summaryWidgetData.total_devices
                  }`}
                />
              </a>
            </div>
            <div className={classes.statusSummaryItem}>
              <h4 className={classes.StatusSummaryItemTitle}>Profit today</h4>
              <CircularProgressbar
                className={classes.StatusSummaryItemWidget}
                value={summaryWidgetData.profit_today}
                text={`${summaryWidgetData.profit_today || 0}`}
              />
            </div>
            <div className={classes.statusSummaryItem}>
              <h4 className={classes.StatusSummaryItemTitle}>Active alarms</h4>
              <CircularProgressbar
                className={classes.StatusSummaryItemWidget}
                value={summaryWidgetData.activeAlarms}
                text={`${summaryWidgetData.activeAlarms || 0}`}
              />
            </div>
          </div>
          <div className={classes.statusSummaryContainer}>
            <div className={classes.statusSummaryItem}>
              <h4 className={classes.StatusSummaryItemTitle}>
                Energy generated(KWh)
              </h4>
              <CircularProgressbar
                className={classes.StatusSummaryItemWidget}
                value={(
                  (summaryWidgetData.total_energy_generated_this_day * 100) /
                  summaryWidgetData.total_energy_generation_limit_this_day
                ).toFixed(1)}
                text={summaryWidgetData.total_energy_generated_this_day.toFixed(
                  1
                )}
              />
            </div>
            <div className={classes.statusSummaryItem}>
              <h4 className={classes.StatusSummaryItemTitle}>
                Energy consumed(KWh)
              </h4>
              <CircularProgressbar
                className={classes.StatusSummaryItemWidget}
                value={(
                  (summaryWidgetData.total_energy_consumed_this_day * 100) /
                  summaryWidgetData.total_energy_consumption_limit_this_day
                ).toFixed(1)}
                text={summaryWidgetData.total_energy_consumed_this_day.toFixed(
                  1
                )}
              />
            </div>
            <div className={classes.statusSummaryItem}>
              <h4 className={classes.StatusSummaryItemTitle}>
                Energy exported(KWh)
              </h4>
              <CircularProgressbar
                className={classes.StatusSummaryItemWidget}
                value={(
                  (summaryWidgetData.total_energy_exported_this_day * 100) /
                  summaryWidgetData.total_energy_export_limit_this_day
                ).toFixed(1)}
                text={summaryWidgetData.total_energy_exported_this_day.toFixed(
                  1
                )}
              />
            </div>
            <div className={classes.statusSummaryItem}>
              <h4 className={classes.StatusSummaryItemTitle}>
                Energy imported(KWh)
              </h4>
              <CircularProgressbar
                className={classes.StatusSummaryItemWidget}
                value={(
                  (summaryWidgetData.total_energy_imported_this_day * 100) /
                  summaryWidgetData.total_energy_import_limit_this_day
                ).toFixed(1)}
                text={
                  summaryWidgetData.total_energy_imported_this_day &&
                  summaryWidgetData.total_energy_imported_this_day.toFixed(1)
                }
              />
            </div>
          </div>
        </GridItem>
      </GridContainer>
    );
  }

  renderExternalDashboard() {
    const { classes } = this.props;
    const widgets = this.state.widgets || [];
    let dashboardWidget = widgets.find(
      widgetData =>
        widgetData.widget &&
        widgetData.widget.metadata &&
        widgetData.widget.metadata.source === "superset"
    );

    if (dashboardWidget == null) return null;

    let dashboardWidgetData = dashboardWidget.dashboard_data;

    return (
      <div className={classes.ExternalDashboardContainer}>
        <div className={classes.ExternalDashboardTitle}>
          {dashboardWidgetData.title}
        </div>
        {this.renderDashItem(
          Object.keys(dashboardWidgetData.position).map(
            key => dashboardWidgetData.position[key]
          )
        )}
      </div>
    );
  }

  renderDashItem(items = null, parent = null) {
    // const { classes } = this.props;

    if (parent === null) {
      parent = items && items.find(item => item.type === "ROOT");
    } else {
      parent = items && items.find(item => item.id === parent);
    }

    if (parent === null) return null;

    let children = parent.children;

    return (
      <div
        key={`${parent.id}-${parent.type}`}
        className={`ExternalDashboard${parent.type}`}
      >
        {parent.type === "CHART" && this.renderExternalChart(parent)}
        {children &&
          children.map(childItem => {
            return this.renderDashItem(items, childItem);
          })}
      </div>
    );
  }

  renderExternalChart(chartPositionData) {
    const { classes } = this.props;
    const dashboardWidget =
      this.state.widgets &&
      this.state.widgets.find(
        widgetData =>
          widgetData.widget &&
          widgetData.widget.metadata &&
          widgetData.widget.metadata.source === "superset"
      );
    const chartData =
      dashboardWidget.dashboard_data.charts &&
      dashboardWidget.dashboard_data.charts.find(
        chart => chart.name === chartPositionData.meta.sliceName
      );

    if (chartData.data.colnames == null || chartData.data.colnames.length < 1) {
      return null;
    }

    if (chartData.data.data == null || chartData.data.data.length < 1) {
      return null;
    }

    return (
      <div
        key={`${chartPositionData.meta.chartId}`}
        className={classes.ExternalDashboardChart}
        // style={{
        //   height: chartPositionData.meta.height,
        //   width: chartPositionData.meta.width
        // }}
      >
        <div className={classes.ExternalDashboardChartTitle}>
          {chartPositionData.meta.sliceName}
        </div>
        <Chart type="chart" chartConfig={chartData} />
      </div>
    );
  }

  renderDevices() {
    const widgets = this.state.widgets || [];
    let favoriteWidget = widgets.find(
      widgetData => widgetData.widget.name === "Favorite Devices"
    );

    if (favoriteWidget === undefined) return null;

    const favoriteDevices =
      (favoriteWidget.metadata && favoriteWidget.metadata.favorite_devices) ||
      [];

    return (
      <GridContainer>
        {this.state.devices &&
          this.state.devices.map(deviceData => {
            if (favoriteDevices.includes(deviceData.id)) {
              return (
                <GridItem
                  xs={12}
                  sm={12}
                  md={6}
                  key={`widget-${deviceData.id}`}
                >
                  {this.renderDevice(deviceData)}
                </GridItem>
              );
            } else {
              return null;
            }
          })}
      </GridContainer>
    );
  }

  renderDevice(deviceData) {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    const timeNow = dayjs();
    const lastDataSyncTime =
      deviceData.other_data && deviceData.other_data.last_data_sync_time;
    const isDeviceActive =
      lastDataSyncTime && timeNow.diff(dayjs(lastDataSyncTime), "days") < 1
        ? true
        : false;
    const hasNotifications = false;

    return (
      <Card>
        <CardHeader color="info" stats icon>
          <CardIcon color="info" className={classes.deviceSummaryCardIcon}>
            <img
              src={`${MEDIA_ROOT}${deviceData.face}`}
              alt="face"
              width="200px"
              height="150px"
            />
          </CardIcon>
          <div className="deviceDetailsIconsContainer">
            <div className="deviceActionsContainer">
              <h6 className="deviceAlias">{deviceData.alias}</h6>
            </div>
            <div className="devStatusIconsContainer">
              <FavoriteIcon
                className="devStatusIcon"
                color="primary"
                onClick={this.markDeviceAsFavorite(deviceData, false)}
              />
              {isDeviceActive ? (
                <WifiIcon className="devStatusIcon" color="primary" />
              ) : (
                <SignalWifiOff className="devStatusIcon" color="disabled" />
              )}
              {hasNotifications ? (
                <NotificationsActiveIcon
                  className="devStatusIcon"
                  color="primary"
                />
              ) : (
                <NotificationsIcon className="devStatusIcon" color="disabled" />
              )}
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <div>
              IP Address: <b>{deviceData.ip_address}</b>
              <br />
              Type: <b>{deviceData.type}</b>
              <br />
              Last Updated:{" "}
              <b>
                {dayjs(lastDataSyncTime).format(DATE_DISPLAY_FORMAT_DETAILED)}
              </b>
            </div>
          </GridContainer>
        </CardBody>
        <CardFooter stats>
          <GridContainer>
            <div className={"deviceLocationContainer"} xs={12} sm={12} md={12}>
              <MyLocation /> {deviceData.address}
            </div>
            <div className="actionButtonsContainer">
              <Button
                color="info"
                className={classes.marginRight}
                onClick={e =>
                  this.props.history.push(
                    `devicedetails/${deviceData.ip_address}`
                  )
                }
              >
                Details
              </Button>
              <Button
                color="info"
                className={classes.marginRight}
                onClick={e =>
                  this.props.history.push(
                    `devicereports/${deviceData.ip_address}`
                  )
                }
              >
                Report
              </Button>
            </div>
          </GridContainer>
        </CardFooter>
      </Card>
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {this.renderStatusSummary()}
        </GridItem>
        <GridContainer xs={12} sm={12} md={12}>
          {this.renderExternalDashboard()}
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3>Favorite Devices:</h3>
          {this.renderDevices()}
        </GridItem>
      </GridContainer>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.any
};

export default withStyles(dashboardStyle)(Dashboard);
