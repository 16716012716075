import React from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import { staticData } from "config.js";

export function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {staticData.MadeWithLove.tagline}
      <Link color="inherit" href={staticData.MadeWithLove.orgLink}>
        {staticData.MadeWithLove.orgTitle}
      </Link>
      {" team."}
    </Typography>
  );
}
