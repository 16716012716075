/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import Table from "components/Table/Table.jsx";

import NavPills from "components/NavPills/NavPills.js";

import { getDeviceReports } from "../../services/dashboardDataProvider";

import "react-circular-progressbar/dist/styles.css";
// import { MEDIA_ROOT } from "config.js";

import bulbIcon from "assets/img/bulb.webp";
import cflIcon from "assets/img/CFL.png";
import acIcon from "assets/img/air-conditioner.webp";
import fanIcon from "assets/img/fan.jpg";
import fridgeIcon from "assets/img/fridge.webp";
import pumpIcon from "assets/img/pump.png";
import motorIcon from "assets/img/motor.jpeg";

import { dayjs, DATE_DISPLAY_FORMAT_DETAILED } from "utils/time.jsx";

const loadIcons = {
  Bulb: bulbIcon,
  CFL: cflIcon,
  AC: acIcon,
  Fan: fanIcon,
  Refrigerator: fridgeIcon,
  "Water pump": pumpIcon,
  "Agriculture Machine": motorIcon
};

const styles = {
  img: {
    width: "auto",
    height: "auto",
    verticalAlign: "middle",
    border: "0"
  },
  loadOnImg: {
    width: "60px",
    height: "auto",
    backgroundColor: "lightgreen"
  },
  loadOffImg: {
    width: "60px",
    height: "auto"
  },
  cardTitleBlack: {
    color: "#273746",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "600",
    fontFamily: "'Josefin Sans', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  statusSummaryContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  statusSummaryItem: {
    margin: "10px"
  },
  cardIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  summaryPieChart: {
    height: "400px",
    marginBottom: "80px"
  },
  summaryTimeChart: {
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  summaryDaysChart: {
    paddingTop: "20px !important",
    paddingBottom: "20px"
  }
};

class DeviceReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceId: null,
      devices: []
    };

    this.tabChanged = this.tabChanged.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    let deviceId = this.props.match.params && this.props.match.params.deviceId;
    if (deviceId) {
      getDeviceReports(this.props.axios, deviceId, "yesterday").then(data => {
        this.setState({ deviceId: deviceId, deviceData: data });
      });
    }
  }

  tabChanged(tabIndex) {
    let deviceId = this.props.match.params && this.props.match.params.deviceId;
    if (deviceId) {
      if (tabIndex === 0) {
        getDeviceReports(this.props.axios, deviceId, "yesterday").then(data => {
          this.setState({
            tabIndex: tabIndex,
            deviceId: deviceId,
            deviceData: data
          });
        });
      } else if (tabIndex === 1) {
        getDeviceReports(this.props.axios, deviceId, "week").then(data => {
          this.setState({
            tabIndex: tabIndex,
            deviceId: deviceId,
            deviceData: data
          });
        });
      } else if (tabIndex === 2) {
        getDeviceReports(this.props.axios, deviceId, "month").then(data => {
          this.setState({
            tabIndex: tabIndex,
            deviceId: deviceId,
            deviceData: data
          });
        });
      }
    }
  }

  renderEnergySummaryChart(reportData) {
    let summaryData = reportData;
    if (
      reportData.per_day_energy_statistics &&
      reportData.per_day_energy_statistics.summary
    ) {
      summaryData = reportData.per_day_energy_statistics.summary;
    }
    const { classes } = this.props;

    function round(num) {
      var m = Number((Math.abs(num) * 100).toPrecision(15));
      return (Math.round(m) / 100) * Math.sign(num);
    }

    let generated = round(
      (summaryData.generated !== undefined
        ? summaryData.generated
        : summaryData.energy_generated) || 0
    );
    let imported = round(
      (summaryData.imported !== undefined
        ? summaryData.imported
        : summaryData.energy_imported) || 0
    );
    let exported = round(
      (summaryData.exported !== undefined
        ? summaryData.exported
        : summaryData.energy_exported) || 0
    );
    let consumed = round(
      (summaryData.consumed !== undefined
        ? summaryData.consumed
        : summaryData.energy_consumed) || 0
    );

    let total_in = generated + imported;

    let options = {
      legend: {
        data: ["Energy In", "Energy Out"]
      },
      radar: {
        // shape: 'circle',
        indicator: [
          { name: "Generated", max: total_in },
          { name: "Imported", max: total_in },
          { name: "Consumed", max: total_in },
          { name: "Exported", max: total_in }
        ]
      },
      series: [
        {
          name: "Energy In vs Energy Out",
          type: "radar",
          data: [
            {
              value: [generated, imported, 0, 0],
              name: "Energy In",
              areaStyle: {
                color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                  {
                    color: "rgba(145, 255, 124, 0.1)",
                    offset: 0
                  },
                  {
                    color: "rgba(145, 255, 124, 0.9)",
                    offset: 1
                  }
                ])
              }
            },
            {
              value: [0, 0, exported, consumed],
              name: "Energy Out",
              areaStyle: {
                color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                  {
                    color: "rgba(255, 145, 124, 0.1)",
                    offset: 0
                  },
                  {
                    color: "rgba(255, 145, 124, 0.9)",
                    offset: 1
                  }
                ])
              }
            }
          ]
        }
      ]
    };
    return (
      <div className={classes.summaryPieChart}>
        <h4>
          <strong>{"Net energy summary (last 7 days):"}</strong>
        </h4>
        <ReactEcharts option={options} />
      </div>
    );
  }

  renderEnergyTimeChart(energyData) {
    const { classes } = this.props;
    let dataToday = [];

    let currentDataPoint = {};
    let lastDataArrivalTime = "";

    energyData.forEach(dataPoint => {
      if (currentDataPoint.data_arrival_time !== lastDataArrivalTime) {
        currentDataPoint = {
          data_arrival_time: dataPoint.data_arrival_time + "+0000"
        };
      }

      if (dataPoint.meter_name === "solar_meter") {
        currentDataPoint["powerGenerated"] = parseFloat(dataPoint.power);
      } else if (dataPoint.meter_name === "load_meter") {
        currentDataPoint["powerConsumed"] = parseFloat(dataPoint.power);
      } else if (dataPoint.meter_name === "grid_meter") {
        currentDataPoint["powerExported"] = -1 * parseFloat(dataPoint.power);
      }

      if (Object.keys(currentDataPoint).length === 4) {
        dataToday.push(currentDataPoint);
        currentDataPoint = {};
      }

      lastDataArrivalTime = dataPoint.data_arrival_time + "+0000";
    });

    let options = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985"
          }
        }
      },
      legend: {
        data: ["Power generated(W)", "Power consumed(W)", "Power exported(W)"]
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      grid: {
        left: "1%",
        right: "1%",
        bottom: "1%",
        containLabel: true
      },
      xAxis: [
        {
          boundaryGap: false,
          data: dataToday.map(dp => dayjs(dp.data_arrival_time).format("LT"))
        }
      ],
      yAxis: [
        {
          type: "value"
        }
      ],
      series: [
        {
          name: "Power Generated(W)",
          type: "line",
          areaStyle: {},
          emphasis: {
            focus: "series"
          },
          data: dataToday.map(dp => dp.powerGenerated)
        },
        {
          name: "Power consumed(W)",
          type: "line",
          areaStyle: {},
          emphasis: {
            focus: "series"
          },
          data: dataToday.map(dp => dp.powerConsumed)
        },
        {
          name: "Power exported(W)",
          type: "line",
          areaStyle: {},
          emphasis: {
            focus: "series"
          },
          data: dataToday.map(dp => dp.powerExported)
        }
      ]
    };

    return (
      <div className={classes.summaryTimeChart}>
        <h6>
          <strong>{"Generation, Consumption, Export over time"}</strong>
        </h6>
        <ReactEcharts option={options} />
      </div>
    );
  }

  renderReportSummary(reportData) {
    const { classes } = this.props;

    return (
      <div className={classes.reportSummary}>
        <Table
          tableHeaderColor="primary"
          tableData={[
            ["Device", reportData.device],
            ["IP Address", reportData.device_ip_address],
            [
              "Form",
              dayjs(reportData.from_time).format(DATE_DISPLAY_FORMAT_DETAILED)
            ],
            [
              "Till",
              dayjs(reportData.to_time).format(DATE_DISPLAY_FORMAT_DETAILED)
            ],
            [
              "Report Generated At",
              dayjs(reportData.report_generation_time).format(
                DATE_DISPLAY_FORMAT_DETAILED
              )
            ],
            [
              "Data Availability",
              `${(reportData.active_data_days || 0).toFixed(2)}`
            ],
            [
              "Energy Generated",
              (reportData.energy_generated || 0).toFixed(2) + "kWh"
            ],
            [
              "Energy Consumed",
              (reportData.energy_consumed || 0).toFixed(2) + "kWh"
            ],
            [
              "Energy Exported",
              (reportData.energy_exported || 0).toFixed(2) + "kWh"
            ],
            [
              "Energy Imported",
              (reportData.energy_imported || 0).toFixed(2) + "kWh"
            ],
            [
              "Per unit rate",
              `${(reportData.consumption_rate || 0).toFixed(2)} ${
                reportData.currency
              }`
            ],
            [
              "Consumption Bill Amount",
              `${(reportData.consumption_bill || 0).toFixed(2)} ${
                reportData.currency
              }`
            ],
            [
              "Money Saved",
              `${(reportData.savings || 0).toFixed(2)} ${reportData.currency}`
            ],
            [
              "Net Bill Amount",
              `${(reportData.net_bill || 0).toFixed(2)} ${reportData.currency}`
            ],
            [
              "Total Investment Amount",
              `${(reportData.total_investment || 0).toFixed(2)} ${
                reportData.currency
              }`
            ],
            [
              "Total Investment Recovered",
              `${(reportData.total_recovery_amount || 0).toFixed(2)} ${
                reportData.currency
              }`
            ]
          ]}
          coloredColls={[3]}
          colorsColls={["primary"]}
        />
      </div>
    );
  }

  renderLastDaysReportTable(reportData) {
    const { classes } = this.props;
    const reportTypes = ["day", "week", "month"];
    const reportCount = ["8", "", ""];

    let tableData = Object.keys(reportData).map(key => [
      [key],
      [(reportData[key]["solar_meter"] || 0).toFixed(2)],
      [(reportData[key]["load_meter"] || 0).toFixed(2)],
      [(reportData[key]["import_energy_meter"] || 0).toFixed(2)],
      [(reportData[key]["export_energy_meter"] || 0).toFixed(2)]
    ]);

    let summaryData = {
      solar_meter: 0,
      load_meter: 0,
      import_energy_meter: 0,
      export_energy_meter: 0
    };

    Object.keys(reportData).forEach(key => {
      summaryData["solar_meter"] =
        summaryData["solar_meter"] + (reportData[key]["solar_meter"] || 0);
      summaryData["load_meter"] =
        summaryData["load_meter"] + (reportData[key]["load_meter"] || 0);
      summaryData["import_energy_meter"] =
        summaryData["import_energy_meter"] +
        (reportData[key]["import_energy_meter"] || 0);
      summaryData["export_energy_meter"] =
        summaryData["export_energy_meter"] +
        (reportData[key]["export_energy_meter"] || 0);
    });

    tableData.push([
      [<b key="summary">Sum</b>],
      [
        <b key="summary-solar_meter">{summaryData["solar_meter"].toFixed(2)}</b>
      ],
      [<b key="summary-load_meter">{summaryData["load_meter"].toFixed(2)}</b>],
      [
        <b key="summary-import_energy_meter">
          {summaryData["import_energy_meter"].toFixed(2)}
        </b>
      ],
      [
        <b key="summary-export_energy_meter">
          {summaryData["export_energy_meter"].toFixed(2)}
        </b>
      ]
    ]);

    return (
      <div className={classes.summaryDaysChart}>
        <h6>
          <strong>
            {`Last ${reportCount[this.state.tabIndex || 0]} ${
              reportTypes[this.state.tabIndex || 0]
            }s details:`}
          </strong>
        </h6>
        <Table
          tableHeaderColor="primary"
          tableHead={[
            `${reportTypes[this.state.tabIndex || 0]}`,
            "Generated (KWh)",
            "Consumed (KWh)",
            "Imported (KWh)",
            "Exported (KWh)"
          ]}
          tableData={tableData}
          coloredColls={[3]}
          colorsColls={["primary"]}
        />
      </div>
    );
  }

  renderYesterdaysReport(deviceId, reportData) {
    const { classes } = this.props;
    const reportTypes = ["Yesterday's", "Last week's", "Last month's"];
    return (
      <div>
        <CardHeader className={classes.cardHeaderHover}>
          <h3>
            <b>{`${
              reportTypes[this.state.tabIndex || 0]
            } report for ${deviceId}`}</b>
          </h3>
        </CardHeader>
        <CardBody>
          {this.renderReportSummary(reportData)}
          {reportData.power_history_yesterday &&
            this.renderEnergyTimeChart(reportData.power_history_yesterday)}
          {this.renderEnergySummaryChart(reportData)}
          {reportData.per_day_energy_statistics &&
            this.renderLastDaysReportTable(
              reportData.per_day_energy_statistics.by_time
            )}
        </CardBody>
      </div>
    );
  }

  renderDeviceLoadInfo(deviceData) {
    const { classes } = this.props;
    const loadData = deviceData.loads_today;
    const currentLoad = deviceData.current_load || [];
    const currency = deviceData.properties.currency || "$";

    let loadInfo = Object.keys(loadData).map(loadName => {
      let data = { ...loadData[loadName] };
      currentLoad.forEach(loadInfo => {
        if (loadInfo.name === loadName) {
          data["currentlyRunning"] = loadInfo.qty;
          data["currentPower"] = loadInfo.power;
        } else {
          data["currentlyRunning"] = 0;
          data["currentPower"] = 0;
        }
      });
      data["name"] = loadName;
      return data;
    });

    return (
      loadData && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h3>
              <strong>{"Today's load details"}</strong>
            </h3>
            <Table
              tableHeaderColor="warning"
              tableHead={[
                "Load",
                "Currently Running",
                "Current Power",
                "Energy",
                "Amount",
                "Hours"
              ]}
              tableData={loadInfo.map(load => [
                <img
                  className={
                    load.currentlyRunning > 0
                      ? classes.loadOnImg
                      : classes.loadOffImg
                  }
                  key={load.name}
                  src={loadIcons[load.name]}
                  alt="..."
                />,
                load.currentlyRunning || 0,
                `${
                  load.currentPower
                    ? (load.currentPower / 1000.0).toFixed(1)
                    : "0"
                } KW`,
                `${load.energy.toFixed(1)} KWh`,
                `${load.money.toFixed(1)} ${currency}`,
                `${load.hours} hrs`
              ])}
              coloredColls={[3]}
              colorsColls={["primary"]}
            />
          </GridItem>
        </GridContainer>
      )
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    const { deviceData } = this.state;
    let deviceId = this.props.match.params && this.props.match.params.deviceId;
    if (deviceData === undefined) return null;
    return (
      <GridContainer key={`project-detail`}>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          key={`project-${this.props.projectId}`}
        >
          <Card chart className={classes.cardHover}>
            <CardBody>
              <NavPills
                color="warning"
                onChange={this.tabChanged}
                vertical={{
                  tabsGrid: { xs: 12, sm: 12, md: 12 },
                  contentGrid: { xs: 12, sm: 12, md: 12 }
                }}
                tabs={[
                  {
                    tabButton: "Yesterday",
                    //tabIcon: SettingsApplications,
                    tabContent: this.renderYesterdaysReport(
                      deviceId,
                      deviceData
                    )
                  },
                  {
                    tabButton: "Weekly",
                    //tabIcon: SettingsApplications,
                    tabContent: this.renderYesterdaysReport(
                      deviceId,
                      deviceData
                    )
                  },
                  {
                    tabButton: "Monthly",
                    //tabIcon: SettingsApplications,
                    tabContent: this.renderYesterdaysReport(
                      deviceId,
                      deviceData
                    )
                  }
                ]}
              />
            </CardBody>
            <CardFooter stats>
              <a href="/dashboard" className={classes.marginRight}>
                Home
              </a>
              <a
                href={`/devicedetails/${deviceId}/`}
                className={classes.marginRight}
              >
                Details
              </a>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

DeviceReports.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DeviceReports);
