import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import Assignment from "@material-ui/icons/Assignment";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { getMyUsers } from "../../services/advisorUsers.js";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class MyUsers extends React.Component {
  state = {
    value: 0,
    users: []
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    getMyUsers(this.props.axios).then(data => {
      this.setState({ users: data });
    });
  }

  handleClick = (name, idx) => e => {
    if (name === "quiz") {
      this.props.history.push(`/quizresponse/${idx}`);
    } else if (name === "chat") {
      this.props.history.push(`/chat/${idx}`);
    }
  };

  renderUsers() {
    const { classes } = this.props;
    let users = this.state.users;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Users</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["User", "Email", "City", "", ""]}
                tableData={users.map((userData, idx) => {
                  return [
                    `${userData.first_name} ${userData.last_name}`,
                    userData.email,
                    userData.city,
                    <Button
                      color={"success"}
                      key={`quizbutton-${idx}`}
                      onClick={this.handleClick("quiz", userData.id)}
                    >
                      Check Quiz Responses
                    </Button>,
                    <Button
                      color={"success"}
                      key={`chatbutton-${idx}`}
                      onClick={this.handleClick("chat", userData.id)}
                    >
                      Chat
                    </Button>
                  ];
                })}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  render() {
    return <div>{this.renderUsers()}</div>;
  }
}

MyUsers.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(MyUsers);
