import { API_ROOT } from "config.js";

const ADVISOR_ENDPOINT = `${API_ROOT}/api/advisor`;

export async function getAnswers(axios, userId, formId, day) {
  let url = `${ADVISOR_ENDPOINT}/formresponse/?userId=${userId}&&formId=${formId}`;
  if (day) {
    url = `${url}&&day=${day}`;
  }
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateAnswers(axios, userId, formId, payload) {
  let url = `${ADVISOR_ENDPOINT}/formresponse/?userId=${userId}&&formId=${formId}`;
  const response = await axios.put(url, payload);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getUserForms(axios, userId, onlyAnsweredForms) {
  const response = await axios.get(
    `${ADVISOR_ENDPOINT}/userforms/?userId=${userId}&onlyAnsweredForms=${onlyAnsweredForms}`
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getMyUsers(axios) {
  const response = await axios.get(`${ADVISOR_ENDPOINT}/users/`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getUserPlans(axios, userId) {
  const response = await axios.get(
    `${ADVISOR_ENDPOINT}/recommendations/?userId=${userId}`
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateUserPlan(axios, userId, recommId, payload) {
  let url = `${ADVISOR_ENDPOINT}/recommendations/?userId=${userId}`;
  if (recommId) {
    url = `${url}&recommendationId=${recommId}`;
  }
  const response = await axios.post(url, payload);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
