/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import MapLeft from "components/MapLeft/MapLeft.jsx";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";

// core component
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { prettyJson } from "../../utils/converters.jsx";
import { DEVICE_TYPES } from "../../utils/deviceTypes.jsx";
import { updateUserDevice } from "../../services/dashboardDataProvider";

// import { MEDIA_ROOT } from "config.js";

const AVAILABLE_STATUS_TYPES = [
  { text: "Meter", value: "meter" },
  { text: "Alarm", value: "alarm" },
  { text: "Device", value: "device" },
  { text: "Report", value: "report" }
];

const AVAILABLE_STATUS_TRIGGER_TYPES = [
  { text: "Data", value: "data" },
  { text: "Schedule", value: "schedule" },
  { text: "Data and Schedule", value: "data/schedule" }
];

const styles = {
  statusSummaryControls: {
    margin: "10px"
  },
  detailsContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  metersContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  deviceInputActions: {
    display: "flex",
    justifyContent: "space-between"
  },
  deviceTypeSelectorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  rawTelemetryDataContainer: {
    display: "flex",
    flexDirection: "column",
    overflowX: "scroll"
  },
  jsonContainer: {
    display: "flex",
    flexDirection: "column"
  },
  jsonKeyValuePair: {
    display: "flex",
    flexDirection: "row"
  },
  jsonKey: {
    fontSize: "larger",
    color: "#0057ff",
    display: "flex",
    flexDirection: "row"
  },
  jsonKeyValue: {
    display: "flex",
    flexDirection: "row",
    color: "#ff00a0",
    paddingLeft: "5px"
  },
  alarmActions: {
    display: "flex",
    justifyContent: "flex-end"
  },
  alarmModalBox: {
    padding: "15px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "80%",
    overflow: "scroll",
    opacity: "100%",
    zIndex: 2000,
    backgroundColor: "white",
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    "-webkit-box-shadow": "0 3px 7px rgba(0, 0, 0, 0.3)",
    "-moz-box-shadow": "0 3px 7px rgba(0, 0, 0, 0.3)",
    boxShadow: "0 3px 7px rgba(0, 0, 0, 0.3)",
    "-webkit-background-clip": "padding-box",
    "-moz-background-clip": "padding-box",
    backgroundClip: "padding-box"
  },
  alarmSelectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px"
  }
};

let deviceTypeOptions = [
  { text: "Home", value: "Home" },
  { text: "Charge Controller", value: "Charge Controller" },
  { text: "DELTA-RPI Inverter", value: "DELTA-RPI Inverter" },
  { text: "WEATHER_STATION", value: "WEATHER STATION" },
  { text: "SOLAR HYBRID INVERTER", value: "SOLAR HYBRID INVERTER" },
  { text: "IOT_GATEWAY", value: "IOT_GATEWAY" },
  { text: "SOLAR PUMP", value: "SOLAR PUMP" },
  { text: "OTHER", value: "OTHER" }
];

class DeviceDetailsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editDeviceDetails: false,
      updatedData: {}
    };
  }

  onChange = name => e => {
    let updatedData = this.state.updatedData || {};
    updatedData[name] = e.target.value;
    this.setState({ updatedData: updatedData });
  };

  onSelectionChange = name => value => {
    let updatedData = this.state.updatedData || {};
    updatedData[name] = value;
    this.setState({ updatedData: updatedData });
  };

  onDataChange = (dataType, idx, fieldName) => e => {
    const { deviceData } = this.props;
    let dataList = deviceData[dataType];
    if (dataList && dataList[idx]) {
      let value = e.target.value;
      if (fieldName === "data_schema" || fieldName === "translation_schema") {
        if (this.isValidJson(value)) {
          value = JSON.parse(value);
          dataList[idx][fieldName] = value;
        }
      } else {
        dataList[idx][fieldName] = value;
      }
      this.setState({ [`updated${dataType}`]: dataList });
    }
  };

  onSelectionChangeStatus = (dataType, idx, fieldName) => value => {
    let dataList = this.state[dataType];
    if (dataList && dataList[idx]) {
      dataList[idx][fieldName] = value;
      this.setState({ [`updated${dataType}`]: dataList });
    }
  };

  onMeterTypeSelectionChange = meterId => value => {
    let updatedData = this.state.updatedData || {};
    let meters = updatedData.meters || [];
    let meter = meters.find(mtr => mtr.id === meterId);
    if (meter) {
      meter.meter_type = value;
    } else {
      meter = {
        id: meterId,
        meter_type: value
      };
      meters.push(meter);
    }
    updatedData.meters = meters;
    this.setState({ updatedData: updatedData });
  };

  onStateInputChange = stateName => e => {
    let updatedData = this.state.updatedData || {};
    let properties = updatedData.properties || {};
    properties[stateName] = e.target.value;
    updatedData.properties = properties;
    this.setState({ updatedData: updatedData });
  };

  isValidJson(text) {
    if (typeof text !== "string") {
      return false;
    }
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  }

  updateProfile = deviceId => e => {
    updateUserDevice(this.props.axios, deviceId, this.state.updatedData).then(
      data => {
        this.setState({
          editDeviceDetails: false,
          deviceData: data,
          updatedData: {}
        });
      }
    );
    e.preventDefault();
  };

  addNewStatusType() {
    const { deviceData } = this.props;
    let available_status_types = deviceData.available_status_types || [];
    let l = available_status_types.length;
    available_status_types.push({});
    this.setState({
      visibleStatusType: l,
      available_status_types: available_status_types,
      editStatusTypeDetails: true
    });
  }

  updateStatusType() {
    const { deviceData } = this.props;
    const updatedStatusData = this.state.updatedavailable_status_types;
    let updatedData = this.state.updatedData || {};
    updatedData.available_status_types = updatedStatusData;
    updatedData.available_device_types = deviceData.available_device_types;
    console.log("updatedStatusData", updatedStatusData, this.state, deviceData);
    if (updatedStatusData) {
      updateUserDevice(this.props.axios, deviceData.ip_address, updatedData)
        .then(data => {
          this.setState({
            ...data,
            visibleStatusType: null,
            editStatusTypeDetails: false
          });
        })
        .catch(err => {
          console.log("Failed to update!", err);
          alert("Failed to update!");
        });
    }
  }

  renderDeviceDataTable(deviceData) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3>
            <strong>Details</strong>
          </h3>
          <Table
            tableHeaderColor="primary"
            tableData={[
              ["Name", deviceData.alias],
              ["Type", deviceData.type],
              ["IP Address", deviceData.ip_address],
              ["Installation Date", deviceData.installation_date],
              ["Location", deviceData.address],
              ["Device data SIM Contact", deviceData.device_contact],
              ["Token", deviceData.token]
            ]}
            coloredColls={[3]}
            colorsColls={["primary"]}
          />
        </GridItem>
        {deviceData.meters &&
          deviceData.meters.length > 0 && (
            <GridItem xs={12} sm={12} md={12}>
              <h3>
                <strong>Meters:</strong>
              </h3>
              <Table
                tableHeaderColor="primary"
                tableHead={["Name", "Type"]}
                tableData={deviceData.meters.map(meter => [
                  meter.name,
                  meter.meter_type
                ])}
                coloredColls={[3]}
                colorsColls={["primary"]}
              />
            </GridItem>
          )}
      </GridContainer>
    );
  }

  renderDeviceDataTableEditable(deviceData) {
    const { classes } = this.props;
    const meterTypeOptions = deviceData.available_meter_types
      ? deviceData.available_meter_types.map(meterType => {
          return { text: meterType, value: meterType };
        })
      : [];

    let deviceType = this.state.updatedData.type;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3>
            <strong>Details</strong>
          </h3>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer className={classes.detailsContainer}>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                key="dev-alias-input"
                labelText="Device Name"
                id="alias"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.updatedData.alias || deviceData.alias,
                  onChange: this.onChange("alias")
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <label>Device Type</label>
              <CustomSelect
                labelText={deviceType}
                items={deviceTypeOptions}
                onChange={this.onSelectionChange("type")}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              {`Installation Date: ${deviceData.installation_date}`}
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                key="dev-address-input"
                labelText="Device Address"
                id="alias"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.updatedData.address || deviceData.address,
                  onChange: this.onChange("address")
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                key="dev-contact-input"
                labelText="Device Contact"
                id="alias"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value:
                    this.state.updatedData.device_contact ||
                    deviceData.device_contact,
                  onChange: this.onChange("device_contact")
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <h3>
            <strong>Meters</strong>
          </h3>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer className={classes.metersContainer}>
            {deviceData.meters.map(meter => {
              return (
                <GridItem xs={12} sm={12} md={6} key={`meter-type-${meter.id}`}>
                  <label>{meter.name}</label>
                  <CustomSelect
                    labelText={meter.meter_type}
                    items={meterTypeOptions}
                    onChange={this.onMeterTypeSelectionChange(meter.id)}
                  />
                </GridItem>
              );
            })}
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }

  renderDevicesMap(deviceData) {
    if (
      deviceData.position.latitude == null ||
      deviceData.position.longitude == null
    ) {
      return null;
    }
    return (
      <GridContainer>
        <MapLeft
          center={[deviceData.position.latitude, deviceData.position.longitude]}
          dataPoints={[deviceData.position]}
          zoom={8}
        />
      </GridContainer>
    );
  }

  renderDeviceDataInputTableEditable(deviceData) {
    const inputOptions = [
      "actual_energy_bill_last_month",
      "actual_import_readings",
      "actual_export_readings",
      "total_installation_cost"
    ];
    const deviceType = deviceData.type && deviceData.type[0];

    if (!Object.keys(DEVICE_TYPES).includes(deviceType)) {
      return null;
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3>
            <strong>Device State Input</strong>
          </h3>
        </GridItem>
        <GridContainer>
          {inputOptions.map(inputOption => {
            return (
              <GridItem
                xs={12}
                sm={12}
                md={6}
                key={`input-option-${inputOption}`}
              >
                <CustomInput
                  labelText={inputOption
                    .split("_")
                    .join(" ")
                    .toUpperCase()}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    value:
                      (this.state.updatedData.state &&
                        this.state.updatedData.state[inputOption]) ||
                      (deviceData.properties &&
                        deviceData.properties[inputOption]),
                    onChange: this.onStateInputChange(inputOption)
                  }}
                />
              </GridItem>
            );
          })}
        </GridContainer>
      </GridContainer>
    );
  }

  renderStatusType(statusType, idx) {
    const { classes, user } = this.props;

    return (
      <div key={`status-type-${idx}`} className={classes.alarmsContainer}>
        <div className={classes.alarmActions}>
          <Modal
            hideBackdrop
            open={this.state.visibleStatusType === idx}
            onClose={() => this.setState({ visibleStatusType: null })}
            aria-labelledby="Add"
            aria-describedby="Add/Update status type"
          >
            <Box className={classes.alarmModalBox} sx={{ width: 400 }}>
              <div className={classes.alarmActions}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!(user.isAdmin === true)}
                      checked={this.state.editStatusTypeDetails === true}
                      onChange={() => {
                        this.setState({
                          editStatusTypeDetails: !this.state
                            .editStatusTypeDetails
                        });
                      }}
                    />
                  }
                  label="Edit"
                />
                <IconButton onClick={() => this.removeStatusType(idx)}>
                  <DeleteIcon />
                </IconButton>
              </div>
              <div
                key={`status_type-${idx}`}
                className={classes.deviceTypeContainer}
              >
                <div>
                  <label>Status Type</label>
                  <CustomSelect
                    className={classes.alarmTypeSelector}
                    labelText={statusType.target_type}
                    items={AVAILABLE_STATUS_TYPES}
                    disabled={this.state.editStatusTypeDetails !== true}
                    onChange={this.onSelectionChangeStatus(
                      "available_status_types",
                      idx,
                      "target_type"
                    )}
                  />
                </div>
                <div>
                  <CustomInput
                    labelText="Name"
                    id={`status_type-name-${idx}`}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      defaultValue: statusType.name,
                      disabled: this.state.editStatusTypeDetails !== true,
                      onChange: this.onDataChange(
                        "available_status_types",
                        idx,
                        "name"
                      )
                    }}
                  />
                </div>
                <div>
                  <label>Updated Trigger</label>
                  <CustomSelect
                    className={classes.alarmTypeSelector}
                    labelText={statusType.update_trigger}
                    items={AVAILABLE_STATUS_TRIGGER_TYPES}
                    disabled={this.state.editStatusTypeDetails !== true}
                    onChange={this.onSelectionChangeStatus(
                      "available_status_types",
                      idx,
                      "update_trigger"
                    )}
                  />
                </div>
                <div>
                  {this.state.editStatusTypeDetails !== true ? (
                    <div className={classes.rawTelemetryDataContainer}>
                      {prettyJson(statusType.translation_schema, classes)}
                    </div>
                  ) : (
                    <CustomInput
                      labelText="Schema"
                      id={`status_type-data_schema${idx}`}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: JSON.stringify(
                          statusType.translation_schema
                        ),
                        // error: !this.isValidJson(
                        //   this.state.updatedavailable_status_types &&
                        //     this.state.updatedavailable_status_types[idx]
                        //       .translation_schema
                        // ),
                        disabled: this.state.editStatusTypeDetails !== true,
                        onChange: this.onDataChange(
                          "available_status_types",
                          idx,
                          "translation_schema"
                        )
                      }}
                    />
                  )}
                </div>
              </div>

              <div className={classes.alarmSelectionContainer}>
                <Button
                  disabled={this.state.editStatusTypeDetails !== true}
                  onClick={() => this.updateStatusType()}
                >
                  Update
                </Button>
                <Button
                  onClick={() =>
                    this.setState({
                      visibleStatusType: null,
                      editStatusTypeDetails: false
                    })
                  }
                >
                  Cancel
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    );
  }

  renderStatusTypes(deviceData) {
    const { user, classes } = this.props;
    return (
      <Card>
        <CardHeader color="info">
          <div className={classes.deviceTypeSelectorContainer}>
            <h4 className={classes.cardTitleWhite}>Status Types</h4>
            <IconButton
              onClick={() => this.setState({ statusTypesVisible: true })}
              style={{
                display:
                  this.state.statusTypesVisible !== true ? "block" : "none"
              }}
            >
              <Visibility />
            </IconButton>
            <IconButton
              onClick={() => this.setState({ statusTypesVisible: false })}
              style={{
                display:
                  this.state.statusTypesVisible === true ? "block" : "none"
              }}
            >
              <VisibilityOff />
            </IconButton>
          </div>
        </CardHeader>
        <CardBody>
          {this.state.statusTypesVisible &&
            deviceData.available_status_types &&
            deviceData.available_status_types.map((statusType, idx) => {
              return (
                <GridContainer
                  key={`status_type-${idx}`}
                  className={classes.deviceTypeContainer}
                >
                  <div
                    key={`status_type-selector-${idx}`}
                    className={classes.deviceTypeSelectorContainer}
                  >
                    <h6>{statusType.name}</h6>
                    <div className={classes.alarmActions}>
                      <IconButton
                        onClick={() =>
                          this.setState({ visibleStatusType: idx })
                        }
                        style={{
                          display:
                            this.state.visibleStatusType !== idx
                              ? "block"
                              : "none"
                        }}
                      >
                        <Visibility />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          this.setState({ visibleStatusType: null })
                        }
                        style={{
                          display:
                            this.state.visibleStatusType === idx
                              ? "block"
                              : "none"
                        }}
                      >
                        <VisibilityOff />
                      </IconButton>
                    </div>
                  </div>
                  {this.state.visibleStatusType === idx &&
                    this.renderStatusType(statusType, idx)}
                </GridContainer>
              );
            })}
          {this.state.statusTypesVisible && (
            <div className={classes.alarmActions}>
              <IconButton
                color="info"
                onClick={() => this.addNewStatusType()}
                disabled={!(user.isAdmin || user.isSuperuser)}
              >
                <AddIcon />
              </IconButton>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes, user } = this.props;
    let deviceData = this.props.deviceData;

    if (deviceData.available_device_types != null) {
      deviceTypeOptions = deviceData.available_device_types;
    }

    if (deviceData === undefined) return null;

    if (this.state.deviceData !== undefined) {
      deviceData = this.state.deviceData;
    }
    return (
      <div>
        {this.renderDevicesMap(deviceData)}
        <GridContainer>
          {(user.isAdmin || user.isSuperuser) && (
            <div className={classes.statusSummaryControls}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.editDeviceDetails === true}
                    onChange={() => {
                      this.setState({
                        editDeviceDetails: !this.state.editDeviceDetails
                      });
                    }}
                  />
                }
                label="Edit"
              />
            </div>
          )}
          {!(this.state.editDeviceDetails === true)
            ? this.renderDeviceDataTable(deviceData)
            : this.renderDeviceDataTableEditable(deviceData)}

          {this.state.editDeviceDetails === true
            ? this.renderDeviceDataInputTableEditable(deviceData)
            : null}
          {this.state.editDeviceDetails === true && (
            <GridItem xs={12} sm={12} md={12}>
              <div className={classes.deviceInputActions}>
                <Button
                  color="info"
                  disabled={!this.state.updatedData}
                  onClick={this.updateProfile(deviceData.ip_address)}
                >
                  Update
                </Button>
                <Button
                  color="default"
                  disabled={!this.state.updatedData}
                  onClick={() => {
                    this.setState({
                      editDeviceDetails: false,
                      updatedData: {}
                    });
                  }}
                >
                  Cancel
                </Button>
              </div>
            </GridItem>
          )}
          {this.renderStatusTypes(deviceData)}
        </GridContainer>
      </div>
    );
  }
}

DeviceDetailsTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DeviceDetailsTable);
