/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import withStyles from "@material-ui/core/styles/withStyles";

import { MadeWithLove } from "components/MadeWithLove/MadeWithLove.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import signupStyle from "assets/jss/material-dashboard-react/layouts/signupStyle.jsx";

import { signup } from "services/authProvider.js";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      errorMessages: null
    };
  }
  // eslint-disable-next-line no-unused-vars
  submitForm = e => {
    e.preventDefault();
    signup(this.state.userData).then(data => {
      if (data.success) {
        this.props.history.push("/dashboard");
      } else {
        console.log("ErrorData", data.data);
        this.setState({ errorMessages: data.data });
      }
    });
  };

  handleChange = name => event => {
    let userData = this.state.userData;
    userData[name] = event.target.value;
    this.setState({ userData: userData });
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="username"
                  label="username"
                  name="username"
                  autoComplete="username"
                  onChange={this.handleChange("username")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={this.handleChange("email")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={this.handleChange("password1")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="confirm_password"
                  label="Repeat Password"
                  type="password"
                  id="confirm_password"
                  autoComplete="current-password"
                  onChange={this.handleChange("password2")}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
              <Grid item xs={12}>
                {this.state.errorMessages &&
                  Object.keys(this.state.errorMessages).map(key => {
                    const messages = this.state.errorMessages[key];
                    return messages.map(message => {
                      return (
                        <SnackbarContent
                          message={message}
                          color="danger"
                          key={`error-${key}`}
                        />
                      );
                    });
                  })}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.submitForm}
            >
              Sign Up
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <MadeWithLove />
        </Box>
      </Container>
    );
  }
}

export default withStyles(signupStyle)(SignUp);
