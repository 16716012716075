import React, { Component } from "react";

class CommentBox extends Component {
  constructor(props) {
    super(props);
    this.addComment = this.addComment.bind(this);
  }

  addComment(e) {
    // Prevent the default behaviour of form submit
    e.preventDefault();
    const comment = e.target.elements.comment.value.trim();
    const name = e.target.elements.name && e.target.elements.name.value.trim();

    // eslint-disable-next-line react/prop-types
    this.props.addComment && this.props.addComment(name, comment);
    // Clear input fields
    e.target.elements.comment.value = "";
    if (e.target.elements.name) {
      e.target.elements.name.value = "";
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { title, inputName } = this.props;
    return (
      <div>
        <h4 className="title">{title}</h4>
        <form onSubmit={this.addComment}>
          {inputName && (
            <div className="field">
              <div className="control">
                <input
                  type="text"
                  className="input"
                  name="name"
                  placeholder="Your name"
                />
              </div>
            </div>
          )}
          <div className="field">
            <div className="control">
              <textarea
                className="textarea"
                name="comment"
                placeholder="Add a comment"
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button className="button is-primary">Submit</button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CommentBox;
